import React, { useState, useEffect, useCallback, useRef } from "react";
import { ButtonGroup, Card, Button, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import ReactFlow, {
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Edge,
    Node,
    Connection,
} from "reactflow";
import "reactflow/dist/style.css";
import { AddDataSourceDialog } from "./AddDataSourceDialog";
import {
    createProblemInstanceConnection,
    CreateProblemInstanceConnection,
    getProblemInstance,
    ProblemInstance,
    ProblemInstanceNode,
    removeProblemInstanceConnection,
    removeProblemInstanceNode,
    updateProblemInstanceConnection,
    updateProblemInstanceNode,
} from "../../backendApis";
import { DataSourceNode } from "./DataSourceNode";
import { AddStandardProblemTypeDialog as AddStandardProblemDialog } from "./AddStandardProblemDialog";
import { ProblemTypeNode } from "./ProblemTypeNode";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import UpdateIcon from "@mui/icons-material/Update";
import { NodeData } from "./nodeData";
import {
    nodeToUpdateProblemInstanceNode,
    problemInstanceConnectionToEdge,
    problemInstanceNodeToNode,
} from "./utils";
import { AddCustomProblemDialog } from "./AddCustomProblemDialog";
import { DataTransformationNode } from "./DataTransformationNode";
import { AddDataTransformationDialog } from "./AddDataTransformationDialog";
import { debounce } from "lodash";

const diagramTopOffset = 170;

const nodeTypes = {
    PROBLEM_DATA: DataSourceNode,
    STANDARD_PROBLEM: ProblemTypeNode,
    CUSTOM_PROBLEM: ProblemTypeNode,
    DATA_TRANSFORMATION: DataTransformationNode,
};

interface SolutionDashboardPageArgs {
    liveDataSourceName: string;
    knownProblemName: string;
    customProblemName: string;
    dataTransformationName: string;
}

export const SolutionDashboardPage = (args: SolutionDashboardPageArgs) => {
    let { id } = useParams();

    // manage state of open dialogs
    const [showAddDataSource, setShowAddDataSource] = useState<boolean>(false);
    const [showAddCustomProblem, setShowAddCustomProblem] =
        useState<boolean>(false);
    const [showAddDataTransformation, setShowAddDataTransformation] =
        useState<boolean>(false);

    const [showAddStandardProblem, setShowAddStandardProblem] =
        useState<boolean>(false);

    // current set of nodes and edges
    const [nodes, setNodes, onNodesChange] = useNodesState<NodeData>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    // current width of the diagram
    const [width, setWidth] = useState(100);

    const edgeUpdateSuccessful = useRef(true);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // track then the user is currently making changes
    const [userActive, setUserActive] = useState(false);

    const navigate = useNavigate();

    // called on first load to load the diagram
    useEffect(() => {
        if (!!id) {
            getProblemInstance(id)
                .then(loadProblemInstance)
                .catch((err) => {
                    console.error(err.message);
                });
        }
    }, [id]);

    useEffect(() => {
        const pollFreq = 1000;

        const interval = setInterval(() => {
            // do not update the diagram when the current user is making changes
            if (!!id && !userActive) {
                getProblemInstance(id)
                    .then(loadProblemInstance)
                    .catch((err) => {
                        console.error(err.message);
                    });
            }
        }, pollFreq);
        return () => clearInterval(interval);
    }, [id, userActive, nodes, edges]);

    const loadProblemInstance = (problemInstance: ProblemInstance) => {
        const newNodes: Node[] = [];
        const newEdges: Edge[] = [];

        problemInstance.nodes.forEach((n) => {
            const node = problemInstanceNodeToNode(n);

            // if a node was previously selected, ensure it is still connected post update
            const previousNodeVersion = nodes.find(
                (n) => n.data.id === node.data.id
            );
            if (!!previousNodeVersion && previousNodeVersion.selected) {
                node.selected = previousNodeVersion.selected;
            }

            if (!!node) {
                newNodes.push(node);
            }
        });

        problemInstance.connections.forEach((connection) => {
            const edge = problemInstanceConnectionToEdge(connection);

            // if a edge was previously selected, ensure it is still connected post update
            const previousEdgeVersion = edges.find((e) => e.id === edge.id);

            if (!!previousEdgeVersion && previousEdgeVersion.selected) {
                edge.selected = previousEdgeVersion.selected;
            }

            if (!!edge) {
                newEdges.push(edge);
            }
        });

        setNodes(newNodes);
        setEdges(newEdges);
    };

    // keeps track of the diagram width
    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            setWidth(event[0].contentBoxSize[0].inlineSize);
        });

        const flowChartContainer = document.getElementById(
            "flow-chart-container"
        )?.parentElement;
        if (!!flowChartContainer) {
            resizeObserver.observe(flowChartContainer);
        }
    });

    // called when a new connection is added
    const onConnect = useCallback(
        (params: any) => {
            const payload: CreateProblemInstanceConnection = {
                problem_instance: Number(id),
                source: Number(params.source),
                target: Number(params.target),
            };

            createProblemInstanceConnection(payload).then((connection) => {
                params.id = connection.id.toString();

                setEdges((eds) => addEdge(params, eds));
                setUserActive(false);
            });
        },
        [setEdges]
    );

    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
        setUserActive(true);
    }, []);

    // called when the state of a edge/.connection changes
    // e.g. dragging a connection from one node onto another
    const onEdgeUpdate = useCallback(
        (oldEdge: Edge, newConnection: Connection) => {
            edgeUpdateSuccessful.current = true;

            updateProblemInstanceConnection({
                id: Number(oldEdge.id),
                problem_instance: Number(id),
                source: Number(newConnection.source),
                target: Number(newConnection.target),
            }).then(() => {
                setUserActive(false);
            });

            setEdges(
                edges.map((e) => {
                    if (e.id === oldEdge.id) {
                        if (newConnection.source) {
                            e.source = newConnection.source;
                        }

                        if (newConnection.target) {
                            e.target = newConnection.target;
                        }
                        return e;
                    } else {
                        return e;
                    }
                })
            );
        },
        [edges, setEdges]
    );

    // called whenever a connection is removed
    const onEdgeUpdateEnd = useCallback(
        (_: any, edge: Edge) => {
            if (!edgeUpdateSuccessful.current) {
                removeProblemInstanceConnection(Number(edge.id)).then(() => {
                    setEdges(edges.filter((e) => e.id !== edge.id));
                    setUserActive(false);
                });
            }

            edgeUpdateSuccessful.current = true;
        },
        [setEdges, edges, setUserActive]
    );

    const addProblemNodeToDiagram = (
        problemInstanceNode: ProblemInstanceNode
    ) => {
        const newNode = problemInstanceNodeToNode(problemInstanceNode);

        if (!!newNode) {
            setNodes([...nodes, newNode]);
        }

        setShowAddStandardProblem(false);
        setShowAddDataSource(false);
        setShowAddCustomProblem(false);
        setShowAddDataTransformation(false);
    };

    const updateNode = React.useRef(
        debounce((node: Node<NodeData>) => {
            const problemInstanceNode = nodeToUpdateProblemInstanceNode(node);
            updateProblemInstanceNode(problemInstanceNode).then(
                (updatedNode) => {
                    setUserActive(false);
                }
            );
        }, 100)
    ).current;

    const removeNode = (id: string) => {
        removeProblemInstanceNode(Number(id)).then(() => {});
    };

    const removeConnection = (id: string) => {
        removeProblemInstanceConnection(Number(id)).then(() => {});
    };

    // called whenever a node is updated
    const onNodesChangeWrapper = (nodeChanges: any) => {
        if (Array.isArray(nodeChanges)) {
            for (const nodeChange of nodeChanges) {
                if (nodeChange.type === "position" && nodeChange.dragging) {
                    const node = nodes.find((n) => n.id === nodeChange.id);
                    if (!!node) {
                        setUserActive(true);
                        updateNode(node);
                    }
                }
                if (nodeChange.type === "remove") {
                    removeNode(nodeChange.id);
                }
            }
        }

        return onNodesChange(nodeChanges);
    };

    const onEdgesChangeWrapper = (edgeChanges: any) => {
        if (Array.isArray(edgeChanges)) {
            for (const edgeChange of edgeChanges) {
                if (edgeChange.type === "remove") {
                    removeConnection(edgeChange.id);
                }
            }
        }

        return onEdgesChange(edgeChanges);
    };

    const doubleClickNode = (event: React.MouseEvent, node: Node<NodeData>) => {
        if (node.data.problemData) {
            navigate(`/live-data-sources/${node.data.problemData.id}`);
        }

        if (
            node.data.standardProblem ||
            node.data.customProblem ||
            node.data.dataTransformation
        ) {
            if (node.data.id) {
                navigate(`/solutions-dashboard-node/${node.data.id}`);
            } else {
                setSnackbarMessage(
                    "Cannot inspect unsaved node, please save diagram"
                );
                setOpenSnackbar(true);
            }
        }
    };

    const handleSnackbarClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    if (id === undefined) {
        return <></>;
    }

    return (
        <div
            id="flow-chart-container"
            style={{
                width: width,
                height: window.innerHeight - diagramTopOffset,
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "15px",
                }}
            >
                <div>
                    <ButtonGroup
                        size="large"
                        variant="outlined"
                        aria-label="outlined primary button group"
                    >
                        <Button onClick={() => setShowAddDataSource(true)}>
                            {args.liveDataSourceName}
                        </Button>
                        <Button onClick={() => setShowAddStandardProblem(true)}>
                            {args.knownProblemName}
                        </Button>
                        <Button onClick={() => setShowAddCustomProblem(true)}>
                            {args.customProblemName}
                        </Button>
                        <Button
                            onClick={() => setShowAddDataTransformation(true)}
                        >
                            {args.dataTransformationName}
                        </Button>
                    </ButtonGroup>
                </div>
                {/* <div>{problemInstanceBeingSolved && <div>SOLVING</div>}</div> */}
                {/* <div>
                    <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                    >
                        <Button
                            variant="contained"
                            color={"primary"}
                            disabled={
                                !validInstance ||
                                problemInstanceBeingSolved ||
                                unsavedChanges
                            }
                            onClick={solve}
                        >
                            Solve
                        </Button>
                        <Button
                            variant="contained"
                            color={"primary"}
                            disabled={!unsavedChanges}
                            onClick={save}
                        >
                            Save
                        </Button>
                    </ButtonGroup>
                </div> */}
            </div>

            <Card
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
            >
                <ReactFlow
                    nodeTypes={nodeTypes}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChangeWrapper}
                    onEdgesChange={onEdgesChangeWrapper}
                    onEdgeUpdate={onEdgeUpdate}
                    onEdgeUpdateStart={onEdgeUpdateStart}
                    onEdgeUpdateEnd={onEdgeUpdateEnd}
                    onConnect={onConnect}
                    // defaultEdgeOptions={defaultEdgeOptions}
                    // connectionLineStyle={connectionLineStyle}
                    onNodeDoubleClick={doubleClickNode}
                    deleteKeyCode={["Backspace", "Delete"]}
                >
                    <Background />
                </ReactFlow>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={openSnackbar}
                    onClose={handleSnackbarClose}
                    autoHideDuration={6000}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity="warning"
                        sx={{ width: "100%" }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Card
                    style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                        pointerEvents: "none",
                        width: "185px",
                        padding: "15px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <HelpOutlineIcon
                            color="info"
                            style={{ verticalAlign: "middle" }}
                        />
                        <div style={{ width: "125px" }}>UNSOLVED</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <HourglassTopIcon
                            color="info"
                            style={{
                                verticalAlign: "middle",
                            }}
                        />
                        <div style={{ width: "125px" }}>PENDING</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <UpdateIcon
                            color="info"
                            style={{
                                verticalAlign: "middle",
                            }}
                        />

                        <div style={{ width: "125px" }}>SOLVING</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <DoneIcon
                            color="success"
                            style={{ verticalAlign: "middle" }}
                        />
                        <div style={{ width: "125px" }}>SATISFIED</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <DoneAllIcon
                            color="success"
                            style={{ verticalAlign: "middle" }}
                        />

                        <div style={{ width: "125px" }}>OPTIMUM</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <WarningAmberIcon
                            color="warning"
                            style={{ verticalAlign: "middle" }}
                        />
                        <div style={{ width: "125px" }}>UNSATISFIABLE</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <ErrorOutlineIcon
                            color="error"
                            style={{ verticalAlign: "middle" }}
                        />
                        <div style={{ width: "125px" }}>ERROR</div>
                    </div>
                </Card>
                <Card
                    style={{
                        position: "absolute",
                        bottom: "20px",
                        left: "20px",
                        pointerEvents: "none",
                        width: "185px",
                        padding: "15px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <CheckCircleOutlineIcon
                            color="success"
                            style={{ verticalAlign: "middle" }}
                        />
                        <div style={{ width: "125px" }}>VALID INPUT</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <WarningAmberIcon
                            color="warning"
                            style={{ verticalAlign: "middle" }}
                        />
                        <div style={{ width: "125px" }}>MISSING DATA</div>
                    </div>
                </Card>
            </Card>
            <AddDataSourceDialog
                problemInstanceId={Number(id)}
                displayed={showAddDataSource}
                cancel={() => {
                    setShowAddDataSource(false);
                }}
                create={addProblemNodeToDiagram}
                name={args.liveDataSourceName}
            ></AddDataSourceDialog>
            <AddStandardProblemDialog
                problemInstanceId={Number(id)}
                displayed={showAddStandardProblem}
                cancel={() => {
                    setShowAddStandardProblem(false);
                }}
                create={addProblemNodeToDiagram}
                name={args.knownProblemName}
            ></AddStandardProblemDialog>
            <AddCustomProblemDialog
                problemInstanceId={Number(id)}
                displayed={showAddCustomProblem}
                cancel={() => {
                    setShowAddCustomProblem(false);
                }}
                create={addProblemNodeToDiagram}
                name={args.customProblemName}
            ></AddCustomProblemDialog>
            <AddDataTransformationDialog
                problemInstanceId={Number(id)}
                displayed={showAddDataTransformation}
                cancel={() => {
                    setShowAddDataTransformation(false);
                }}
                create={addProblemNodeToDiagram}
                name={args.dataTransformationName}
            ></AddDataTransformationDialog>
        </div>
    );
};

// // whenever the diagram connections change, validate the nodes and diagram
// useEffect(() => {
//     nodes.forEach((node) => {
//         validateNode(node);
//     });

//     setValidInstance(nodes.every((n) => n.data.validInput));
// }, [edges]);

// // whenever a node is added or removed
// useEffect(() => {
//     nodes.forEach((node) => {
//         validateNode(node);
//     });

//     setValidInstance(nodes.every((n) => n.data.validInput));
// }, [nodes.length]);

// const getNodeInputs = (node: Node) => {
//     const inputEdges = edges.filter(
//         (edge) => getEdgeTarget(edge)?.id === node.id
//     );
//     const inputNodes = inputEdges.map(getEdgeSource);
//     return inputNodes;
// };

// const getEdgeSource = (edge: Edge) => {
//     return getNodeById(edge.source);
// };

// const getEdgeTarget = (edge: Edge) => {
//     return getNodeById(edge.target);
// };

// const validateNode = (node?: Node) => {
//     if (!node) {
//         return;
//     }
//     const nodeInputSchema = node?.data.inputSchema;
//     const inputNodes = getNodeInputs(node);

//     // could include this check
//     // if (inputNodes.some((x) => x === undefined)) {
//     //     return;
//     // }

//     const inputNodeSchemas = inputNodes
//         .filter((x) => x !== undefined)
//         .map((x) => x?.data.outputSchema) as object[];
//     const jointInputSchema = inputNodeSchemas.reduce(mergeSchema, {});

//     setNodes(
//         nodes.map((n) => {
//             if (n.id === node.id) {
//                 const validInput = schemasMatch(
//                     nodeInputSchema,
//                     jointInputSchema
//                 );

//                 n.data = {
//                     ...node.data,
//                     validInput,
//                 };
//             }

//             return n;
//         })
//     );
// };

// const solve = () => {
//     if (!!id) {
//         solveProblemInstance(Number(id)).then((problemInstance) => {
//             loadProblemInstance(problemInstance);

//             // poll the state of the problemInstance until complete
//             const pollTime = 1000; // ms
//             const pollHandle = setInterval(() => {
//                 if (!!id) {
//                     getProblemInstance(id)
//                         .then((instance) => {
//                             if (!instance.solving) {
//                                 clearInterval(pollHandle);
//                             }

//                             return loadProblemInstance(instance);
//                         })
//                         .catch((err) => {
//                             console.error(err.message);
//                         });
//                 }
//             }, pollTime);
//         });
//     }
// };

// const save = () => {
//     if (!!id) {
//         const payloadNodes: CreateUpdateProblemInstanceNode[] = nodes.map(
//             nodeToCreateProblemInstanceNode
//         );

//         const payloadConnections: CreateProblemInstanceConnection[] = [];

//         edges.forEach((edge) => {
//             const edgeSourceNode = getNodeById(edge.source);
//             const edgeTargetNode = getNodeById(edge.target);

//             if (!!edgeSourceNode && !!edgeTargetNode) {
//                 payloadConnections.push({
//                     problem_instance: Number(id),
//                     source: Number(edgeSourceNode.id),
//                     target: Number(edgeTargetNode.id),
//                 });
//             }
//         });

//         const payload: ProblemInstanceUpdate = {
//             id: Number(id),
//             nodes: payloadNodes,
//             connections: payloadConnections,
//         };

//         updateProblemInstance(payload).then((updatedProblemInstance) => {
//             loadProblemInstance(updatedProblemInstance);
//             // setUnsavedChanges(false);
//         });
//     }
// };

// const getNodeById = (id: string) => {
//     return nodes.find((x) => x.id === id);
// };
