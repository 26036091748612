import { authenticatedFetch } from "./shared";

export interface FrontEndConfig {
    problem_data_name: string;
    standard_problem_name: string;
    custom_problem_name: string;
    problem_instance_name: string;
    data_transformation_name: string;
}

export const getFrontEndConfig = (): Promise<FrontEndConfig> => {
    return authenticatedFetch(`api/v1/front_end_config/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};
