import { authenticatedFetch } from "./shared";

export interface StandardProblemTypeData {
    id: number;
    name: string;
    details: string;
    input_data_schema: object;
    solution_schema: object;
}

export const getStandardProblemTypes = (): Promise<
    StandardProblemTypeData[]
> => {
    return authenticatedFetch(`api/v1/standard_problems/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json.list;
        })
        .catch(() => {});
};

export const getStandardProblemType = (
    id: string
): Promise<StandardProblemTypeData> => {
    return authenticatedFetch(`api/v1/standard_problems/${id}/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};
