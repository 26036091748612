import React, { useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import DataObjectIcon from "@mui/icons-material/DataObject";
import TransformIcon from "@mui/icons-material/Transform";
import TuneIcon from "@mui/icons-material/Tune";
import ListAltIcon from "@mui/icons-material/ListAlt";

import {
    AppBar,
    Avatar,
    Box,
    Button,
    ButtonGroup,
    createTheme,
    CssBaseline,
    Drawer,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ThemeProvider,
    Toolbar,
    Typography,
} from "@mui/material";
import { SignInPage } from "./pages/SignIn";
import { CustomProblemsPage } from "./pages/CustomProblemsPage";
import { XcspDescription } from "./pages/Xcsp";
import { ProblemDataSetsPage } from "./pages/ProblemDataSets";
import { ProblemDataPage } from "./pages/ProblemData";
import { SolutionDashboardsPage } from "./pages/SolutionDashboardsPage";
import { SolutionDashboardPage } from "./pages/ProblemInstance/SolutionDashboardPage";
import { ProblemInstanceNodePage } from "./pages/ProblemInstanceNodePage";
import { StandardProblemsPage } from "./pages/StandardProblemsPage";
import { StandardProblemPage } from "./pages/StandardProblemPage";
import { DataTransformations } from "./pages/DataTransformations";
import { DataTransformation } from "./pages/DataTransformation";
import LineAxisIcon from "@mui/icons-material/LineAxis";

import mosaicImage from "./images/mosaic.png";
import { deepOrange } from "@mui/material/colors";

import "./App.css";
import {
    FrontEndConfig,
    getFrontEndConfig,
} from "./backendApis/frontEndConfig";
import { plural } from "./utils";
import { getUserDetails, UserDetails } from "./backendApis/loomUser";

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: deepOrange,
        // secondary: orange,
        text: {
            primary: "#e0d9d3",
        },
    },
});

const liveDataSourceName = (config: FrontEndConfig | null) =>
    config?.problem_data_name ?? "Live Data Source";

const knownProblemName = (config: FrontEndConfig | null) =>
    config?.standard_problem_name ?? "Known Problem";

const customProblemName = (config: FrontEndConfig | null) =>
    config?.custom_problem_name ?? "Custom Problem";

const solutionsDashboardName = (config: FrontEndConfig | null) =>
    config?.problem_instance_name ?? "Solution Dashboard";

const dataTransformationName = (config: FrontEndConfig | null) =>
    config?.data_transformation_name ?? "Data Transformation";

function Main() {
    const drawerWidth = 250;
    const navigate = useNavigate();
    const [frontEndConfig, setFrontEndConfig] = useState<FrontEndConfig | null>(
        null
    );

    const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

    useEffect(() => {
        getFrontEndConfig()
            .then((data) => {
                setFrontEndConfig(data);
            })
            .catch((err) => {
                console.error(err.message);
            });

        getUserDetails()
            .then((data) => {
                setUserDetails(data);
            })
            .catch((err) => {
                console.error(err.message);
            });
    }, []);

    const logoutClicked = () => {
        localStorage.removeItem("accessToken");
        navigate("/login");
    };

    const menuItems = useMemo(() => {
        return [
            {
                url: "live-data-sources",
                text: plural(liveDataSourceName(frontEndConfig)),
                icon: <DataObjectIcon />,
            },
            {
                url: "known-problems",
                text: plural(knownProblemName(frontEndConfig)),
                icon: <ListAltIcon />,
            },
            {
                url: "custom-descriptions",
                text: plural(customProblemName(frontEndConfig)),
                icon: <TuneIcon />,
            },
            {
                url: "solutions-dashboard",
                text: plural(solutionsDashboardName(frontEndConfig)),
                icon: <LineAxisIcon />,
            },
            {
                url: "data-transformations",
                text: plural(dataTransformationName(frontEndConfig)),
                icon: <TransformIcon />,
            },
        ];
    }, [frontEndConfig]);

    return (
        <Box>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Toolbar>
                    <Avatar
                        sx={{ height: "50px", width: "50px" }}
                        src={mosaicImage}
                    />
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ flexGrow: 1, marginLeft: "10px" }}
                    >
                        Loom
                    </Typography>
                    <ButtonGroup
                        size="large"
                        variant="outlined"
                        aria-label="outlined primary button group"
                    >
                        <Button variant="outlined">
                            {userDetails?.username}
                        </Button>
                        <Button variant="outlined" onClick={logoutClicked}>
                            Logout
                        </Button>
                    </ButtonGroup>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
            >
                <Toolbar />
                {menuItems.map((menuItem) => {
                    return (
                        <Box sx={{ overflow: "auto" }} key={menuItem.url}>
                            <ListItem key={menuItem.url} disablePadding>
                                <ListItemButton
                                    selected={window.location
                                        .toString()
                                        .includes(menuItem.url)}
                                    onClick={() => {
                                        navigate("/" + menuItem.url);
                                    }}
                                >
                                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                                    <ListItemText primary={menuItem.text} />
                                </ListItemButton>
                            </ListItem>
                        </Box>
                    );
                })}
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 4,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    position: "relative",
                    left: `${drawerWidth}px`,
                }}
            >
                <Toolbar />
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to="/solutions-dashboard" />}
                    />
                    <Route
                        path="/live-data-sources"
                        element={
                            <ProblemDataSetsPage
                                name={liveDataSourceName(frontEndConfig)}
                            />
                        }
                    />
                    <Route
                        path="/live-data-sources/:id"
                        element={<ProblemDataPage />}
                    />
                    <Route
                        path="/data-transformations"
                        element={
                            <DataTransformations
                                name={dataTransformationName(frontEndConfig)}
                            />
                        }
                    />
                    <Route
                        path="/data-transformations/:id"
                        element={<DataTransformation />}
                    />

                    <Route
                        path="/custom-descriptions"
                        element={
                            <CustomProblemsPage
                                name={customProblemName(frontEndConfig)}
                            />
                        }
                    />
                    <Route
                        path="/xcsp-description/:id"
                        element={<XcspDescription />}
                    />

                    <Route
                        path="/known-problems"
                        element={
                            <StandardProblemsPage
                                name={knownProblemName(frontEndConfig)}
                            />
                        }
                    />
                    <Route
                        path="/known-problems/:id"
                        element={<StandardProblemPage />}
                    />
                    <Route
                        path="/solutions-dashboard"
                        element={
                            <SolutionDashboardsPage
                                name={solutionsDashboardName(frontEndConfig)}
                            />
                        }
                    />
                    <Route
                        path="/solutions-dashboard/:id"
                        element={
                            <SolutionDashboardPage
                                liveDataSourceName={liveDataSourceName(
                                    frontEndConfig
                                )}
                                knownProblemName={knownProblemName(
                                    frontEndConfig
                                )}
                                customProblemName={customProblemName(
                                    frontEndConfig
                                )}
                                dataTransformationName={dataTransformationName(
                                    frontEndConfig
                                )}
                            />
                        }
                    />
                    <Route
                        path="/solutions-dashboard-node/:id"
                        element={<ProblemInstanceNodePage />}
                    />
                </Routes>
            </Box>
        </Box>
    );
}

export default function App() {
    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/login" element={<SignInPage></SignInPage>} />
                    <Route path="*" element={<Main />} />
                </Routes>
            </ThemeProvider>
        </React.StrictMode>
    );
}
