import React, { useState, useEffect } from "react";
import { Box, Button, ButtonGroup, Card, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { useParams } from "react-router-dom";

import { JsonSchemaEditor } from "../component/JsonSchemaEditor";
import { JsonEditor } from "../component/JsonEditor";
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import {
    getProblemData,
    getStandardProblemTypes,
    StandardProblemTypeData,
    updateProblemData,
} from "../backendApis";
import { jsonToFormattedString } from "../utils";
import { BACKEND_URL, getAccessToken } from "../backendApis/shared";

export const ProblemDataPage = () => {
    let { id } = useParams();

    const [name, setName] = useState<string>("");
    const [schema, setSchema] = useState<string>("");
    const [valueData, setValueData] = useState<string>();
    const [valueId, setValueId] = useState<number>(-1);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const [validSchema, setValidSchema] = useState<boolean>(false);
    const [validData, setValidData] = useState<boolean>(false);
    const [standardProblems, setStandardProblems] = useState<
        StandardProblemTypeData[]
    >([]);

    useEffect(() => {
        if (!!id) {
            getProblemData(id)
                .then((problemData) => {
                    setSchema(jsonToFormattedString(problemData.schema));
                    setValueId(problemData.value.id);
                    setValueData(jsonToFormattedString(problemData.value.data));
                    setName(problemData.name);

                    setTimeout(() => setUnsavedChanges(false), 100);
                })
                .catch((err) => {
                    console.error(err.message);
                });

            getStandardProblemTypes()
                .then(setStandardProblems)
                .catch((err) => {
                    console.error(err.message);
                });
        }
    }, [id]);

    const save = () => {
        if (!!id && !!valueData) {
            updateProblemData({
                id: parseInt(id),
                schema: JSON.parse(schema),
                value: {
                    id: valueId,
                    data: JSON.parse(valueData),
                },
            }).then((updatedProblemData) => {});
        }
    };

    const onChangeSchemaEditor = (valid: boolean, schema?: string) => {
        if (valid && !!schema) {
            setSchema(schema);
            setUnsavedChanges(true);
        }
        setValidSchema(valid);
    };

    const onChangeDataEditor = (valid: boolean, data?: string) => {
        if (valid && !!data) {
            setValueData(data);

            setUnsavedChanges(true);
        }

        setValidData(valid);
    };

    const maxEditorHeight = "700px";

    return (
        <div>
            <Box sx={{ flexGrow: 1, paddingBottom: "15px" }}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={{ xs: "column", sm: "row" }}
                    sx={{ fontSize: "12px" }}
                >
                    <Grid sx={{ order: { xs: 2, sm: 1 } }}>
                        <Typography variant="h5">{name}</Typography>
                    </Grid>
                    <Grid
                        container
                        columnSpacing={1}
                        sx={{ order: { xs: 1, sm: 2 } }}
                    >
                        <Grid>
                            <Button
                                variant="contained"
                                color={"primary"}
                                disabled={
                                    !(
                                        unsavedChanges &&
                                        validData &&
                                        validSchema
                                    )
                                }
                                onClick={save}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <div
                style={{
                    display: "flex",
                    flexFlow: "row",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        padding: "0px",
                        marginRight: "10px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "10px",
                            height: "47px",
                        }}
                    >
                        <Typography variant="h5">Schema</Typography>
                        <div style={{ display: "flex", columnGap: "10px" }}>
                            {/* <Typography variant="h6">Templates:</Typography> */}
                            <ButtonGroup variant="outlined">
                                {standardProblems.map((standardProblem) => {
                                    return (
                                        <Button
                                            key={standardProblem.id}
                                            onClick={() =>
                                                setSchema(
                                                    jsonToFormattedString(
                                                        standardProblem.input_data_schema
                                                    )
                                                )
                                            }
                                        >
                                            {standardProblem.name}
                                        </Button>
                                    );
                                })}
                            </ButtonGroup>
                        </div>
                    </div>

                    <Card sx={{ flexGrow: 1 }}>
                        <JsonSchemaEditor
                            height={maxEditorHeight}
                            jsonSchema={schema}
                            onChange={onChangeSchemaEditor}
                        />
                    </Card>
                </div>
                <div
                    style={{
                        width: "100%",
                        padding: "0px",
                        marginLeft: "10px",
                    }}
                >
                    <div style={{ paddingBottom: "10px", height: "47px" }}>
                        <Typography variant="h5">Data</Typography>
                    </div>

                    <Card sx={{ flexGrow: 1 }}>
                        <JsonEditor
                            height={maxEditorHeight}
                            json={valueData}
                            schema={schema}
                            onChange={onChangeDataEditor}
                        ></JsonEditor>
                    </Card>
                </div>
            </div>

            <Typography variant="h5" style={{ marginTop: "15px" }}>
                External Update
            </Typography>
            <Card sx={{ flexGrow: 1 }}>
                <CodeMirror
                    value={`curl -X 'PATCH' \\
  '${BACKEND_URL}api/v1/problem_datasets/${id}/' \\
  -H 'accept: application/json' \\
  -H 'Content-Type: application/json' \\
  -H 'Authorization: Token ${getAccessToken()}' \\
  -d '{
  "value": {"data": {}}
}'`}
                    theme={vscodeDark}
                    readOnly={true}
                ></CodeMirror>
            </Card>
        </div>
    );
};
