import { ExtendedElementSpec, XmlSpec } from "./XmlLinter";

const instantiation: ExtendedElementSpec = {
    name: "instantiation",
    children: ["list", "values"],
    unknownChildElements: "info",
    top: true,
    textAllowed: false,
    attributes: [
        { name: "id", global: false, required: false },
        { name: "type", values: ["solution"], global: false, required: false },
        { name: "cost", global: false, required: false },
    ],
};

const values: ExtendedElementSpec = {
    name: "list",
    children: [],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [],
};

const instance: ExtendedElementSpec = {
    name: "instance",
    children: ["variables", "constraints", "objectives"],
    unknownChildElements: "info",
    top: true,
    textAllowed: false,
    attributes: [
        { name: "format", values: ["XCSP3"], global: false, required: true },
        { name: "type", values: ["COP"], global: false, required: true },
    ],
};

const variables: ExtendedElementSpec = {
    name: "variables",
    children: ["var", "array"],
    unknownChildElements: "info",
    top: false,
    attributes: [],
};

const variable: ExtendedElementSpec = {
    name: "var",
    children: [],
    unknownChildElements: "info",
    top: false,
    textAllowed: "required",
    attributes: [
        { name: "id", global: false, required: true },
        { name: "note", global: false, required: false },
    ],
    unknownAttributes: "info",
};

const array: ExtendedElementSpec = {
    name: "array",
    children: [],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [
        { name: "id", global: false, required: true },
        { name: "size", global: false, required: true },
        { name: "note", global: false, required: false },
    ],
    unknownAttributes: "info",
};

const constraints: ExtendedElementSpec = {
    name: "constraints",
    children: [
        "sum",
        "allDifferent",
        "intension",
        "extension",
        "group",
        "noOverlap",
        "instantiation",
    ],
    unknownChildElements: "info",
    top: false,
    textAllowed: false,
    attributes: [],
};

const noOverlap: ExtendedElementSpec = {
    name: "noOverlap",
    children: ["origins", "lengths"],
    unknownChildElements: "info",
    top: false,
    textAllowed: false,
    attributes: [],
};

const origins: ExtendedElementSpec = {
    name: "origins",
    children: [],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [],
};

const lengths: ExtendedElementSpec = {
    name: "lengths",
    children: [],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [],
};

const sum: ExtendedElementSpec = {
    name: "sum",
    children: ["list", "coeffs", "condition"],
    unknownChildElements: "info",
    top: false,
    textAllowed: false,
    attributes: [],
};

const minimize: ExtendedElementSpec = {
    name: "minimize",
    children: ["list", "coeffs", "condition"],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [
        {
            name: "type",
            values: ["sum", "maximum"],
            global: false,
            required: true,
        },
    ],
};

const maximize: ExtendedElementSpec = {
    name: "maximize",
    children: ["list", "coeffs"],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [
        {
            name: "type",
            values: ["sum", "maximum"],
            global: false,
            required: true,
        },
    ],
    unknownAttributes: "info",
};

const allDifferent: ExtendedElementSpec = {
    name: "allDifferent",
    children: ["list", "except"],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [],
};

const group: ExtendedElementSpec = {
    name: "group",
    children: ["intension", "extension", "args"],
    unknownChildElements: "info",
    top: false,
    textAllowed: false,
    attributes: [],
};

const args: ExtendedElementSpec = {
    name: "args",
    children: [],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [],
};

const list: ExtendedElementSpec = {
    name: "list",
    children: [],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [],
};

const coeffs: ExtendedElementSpec = {
    name: "coeffs",
    children: [],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [],
};

const condition: ExtendedElementSpec = {
    name: "condition",
    children: [],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [],
};

const objectives: ExtendedElementSpec = {
    name: "objectives",
    children: ["maximize", "minimize", "intension"],
    unknownChildElements: "info",
    top: false,
    textAllowed: false,
    attributes: [],
    unknownAttributes: "info",
};

const intension: ExtendedElementSpec = {
    name: "intension",
    children: [],
    unknownChildElements: "info",
    top: false,
    textAllowed: true,
    attributes: [{ name: "id", global: false, required: false }],
    unknownAttributes: "info",
};

const extension: ExtendedElementSpec = {
    name: "extension",
    children: ["list", "supports"],
    unknownChildElements: "info",
    top: false,
    textAllowed: false,
    attributes: [{ name: "id", global: false, required: false }],
    unknownAttributes: "info",
};

export const xcspSpec: XmlSpec = {
    elementSpecs: [
        instantiation,
        instance,
        variables,
        variable,
        constraints,
        array,
        sum,
        noOverlap,
        allDifferent,
        list,
        values,
        coeffs,
        condition,
        objectives,
        maximize,
        minimize,
        intension,
        extension,
        group,
        args,
        origins,
        lengths,
    ],
};
