import React, { useEffect } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    TextField,
} from "@mui/material";

import { CreateProblemInstance } from "../backendApis/problemInstances";

export const CreateProblemInstanceDialog = ({
    displayed,
    cancel,
    create,
    name,
}: {
    displayed: boolean;
    cancel: () => void;
    create: (data: CreateProblemInstance) => void;
    name: string;
}) => {
    const [open, setOpen] = React.useState(displayed);

    const [problemData, setProblemData] = React.useState<CreateProblemInstance>(
        {
            name: "",
            details: "",
        }
    );

    useEffect(() => {
        setOpen(displayed);
    }, [displayed]);

    const handleClose = (created: boolean) => {
        setOpen(false);

        if (created) {
            create(problemData);
        } else {
            cancel();
        }
    };

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <DialogTitle>{`Create ${name}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`Create a new ${name}`}</DialogContentText>
                <FormControl sx={{ m: 1, width: "90%" }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="problemName"
                        label="Name"
                        fullWidth
                        variant="standard"
                        onChange={(event) => {
                            problemData.name = event.target.value;
                            setProblemData(problemData);
                        }}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: "90%" }}>
                    <TextField
                        multiline
                        rows={2}
                        // maxRows={Infinity}
                        margin="dense"
                        id="problemDetails"
                        label="Details"
                        fullWidth
                        variant="standard"
                        onChange={(event) => {
                            problemData.details = event.target.value;
                            setProblemData(problemData);
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Cancel</Button>
                <Button onClick={() => handleClose(true)}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};
