import React, { useEffect } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";

import { RunSolverData, SolverType } from "../backendApis";

export const SolveXcspDialog = ({
    xcspId,
    displayed,
    cancel,
    create,
}: {
    xcspId: number;
    displayed: boolean;
    cancel: () => void;
    create: (data: RunSolverData) => void;
}) => {
    const [open, setOpen] = React.useState(displayed);

    const [selectedSolver, setSelectedSolver] =
        React.useState<SolverType>("ACE");

    const [solverTimeout, setSolverTimeout] = React.useState<number>(60);

    useEffect(() => {
        setOpen(displayed);
    }, [displayed]);

    const handleClose = (created: boolean) => {
        setOpen(false);

        if (created) {
            create({
                xcsp: xcspId,
                solver: selectedSolver,
                timeout: solverTimeout,
            });
        } else {
            cancel();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Solve the current XCSP problem</DialogTitle>
            <DialogContent>
                <DialogContentText>Configure solver</DialogContentText>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel htmlFor="demo-dialog-native">Solver</InputLabel>
                    <Select
                        native
                        value={selectedSolver}
                        onChange={(event: SelectChangeEvent) => {
                            setSelectedSolver(event.target.value as SolverType);
                        }}
                        input={
                            <OutlinedInput
                                label="Solver"
                                id="demo-dialog-native"
                            />
                        }
                    >
                        <option value={"ACE"}>ACE</option>
                        <option value={"PTO"}>PTO</option>
                        <option value={"TSP"}>TSP</option>
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 160 }}>
                    <TextField
                        value={solverTimeout.toString()}
                        fullWidth
                        id="outlined-number"
                        label="Max Time (Sec)"
                        type="number"
                        InputProps={{
                            inputProps: {
                                max: 100,
                                min: 10,
                            },
                        }}
                        onChange={(e) => {
                            var value = parseInt(e.target.value, 10);

                            if (value > 60) value = 60;
                            if (value < 1) value = 1;

                            setSolverTimeout(value);
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Cancel</Button>
                <Button onClick={() => handleClose(true)}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};
