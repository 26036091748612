import React, { useState, useEffect } from "react";

import {
    Fab,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {
    createDataTransformation,
    DataTransformationCreate,
    DataTransformationSummary,
    getDataTransformations,
} from "../backendApis/dataTransformation";
import { CreateDataTransformationDialog } from "../component/CreateDataTransformationDialog";
import { plural } from "../utils";

interface DataTransformationsArgs {
    name: string;
}

export const DataTransformations = (args: DataTransformationsArgs) => {
    const [transformations, setTransformations] = useState<
        DataTransformationSummary[]
    >([]);

    const [showCreate, setShowCreate] = useState<boolean>(false);

    useEffect(() => {
        getDataTransformations()
            .then((data) => {
                setTransformations(data);
            })
            .catch((err) => {
                console.error(err.message);
            });
    }, []);

    const navigate = useNavigate();
    const handleClick = (data: DataTransformationSummary) => {
        navigate(`/data-transformations/${data.id}`);
    };

    const createTransformationClicked = (data: DataTransformationCreate) => {
        createDataTransformation(data)
            .then((transformation) => {
                getDataTransformations()
                    .then((data) => {
                        setTransformations(data);
                    })
                    .catch((err) => {
                        console.error(err.message);
                    });
            })
            .catch(() => {
                console.error("error creating instance");
            })
            .finally(() => {
                setShowCreate(false);
            });
    };

    // TODO: should trigger "are you sure" and list what else would be deleted/changed
    const deleteDataTransformationClicked = (
        transformation: DataTransformationSummary
    ) => {
        // deleteDataTransformation(transformation.id)
        //     .then(() => {
        //         getDataTransformations()
        //             .then((data) => {
        //                 setTransformations(data);
        //             })
        //             .catch((err) => {
        //                 console.error(err.message);
        //             });
        //     })
        //     .catch((err) => {
        //         console.error(err.message);
        //     });
    };

    return (
        <div>
            <Typography variant="h5" style={{ marginBottom: "15px" }}>
                {plural(args.name)}
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Details</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transformations.map((transformation) => (
                            <TableRow
                                key={transformation.id}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                    cursor: "pointer",
                                }}
                                onClick={() => handleClick(transformation)}
                            >
                                <TableCell component="th" scope="row">
                                    {transformation.name}
                                </TableCell>

                                <TableCell>{transformation.details}</TableCell>
                                <TableCell align="right">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                deleteDataTransformationClicked(
                                                    transformation
                                                );
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Fab
                color="primary"
                aria-label="add"
                sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2),
                }}
                onClick={() => {
                    setShowCreate(true);
                }}
            >
                <AddIcon />
            </Fab>
            <CreateDataTransformationDialog
                displayed={showCreate}
                cancel={() => {
                    setShowCreate(false);
                }}
                create={createTransformationClicked}
                name={args.name}
            ></CreateDataTransformationDialog>
        </div>
    );
};
