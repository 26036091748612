import React, { useState, useEffect } from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { useParams } from "react-router-dom";
import XcspEditor from "../component/XcspEditor";
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";

import { SolutionsTable } from "../component/SolutionsTable";
import { SolveXcspDialog } from "../component/SolveXcspDialog";
import {
    getProblemSolutions,
    getXcspInstance,
    RunSolverData,
    XcspSolution,
    submitSolve,
    updateXcspInstance,
    Xcsp,
} from "../backendApis";
import {
    CreateCustomProblemTypeData,
    getCustomProblemType,
} from "../backendApis/customProblemTypes";
import { BACKEND_URL, getAccessToken } from "../backendApis/shared";

export const XcspDescription = () => {
    let { id } = useParams();

    const [problemDetails, setProblemDetails] = useState<
        CreateCustomProblemTypeData | undefined
    >(undefined);

    const [xcsp, setXcsp] = useState<Xcsp | undefined>(undefined);
    const [problemSolutions, setProblemSolutions] = useState<XcspSolution[]>(
        []
    );
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const [validXcsp, setValidXcsp] = useState<boolean>(false);
    const [showSolveDialog, setShowSolveDialog] = useState<boolean>(false);

    useEffect(() => {
        if (!!id) {
            getCustomProblemType(id)
                .then((data) => {
                    setProblemDetails(data);
                    return data;
                })
                .then((data) => {
                    getXcspInstance(data.xcsp as number)
                        .then((data) => {
                            setXcsp(data);
                        })
                        .catch((err) => {
                            console.error(err.message);
                        });
                    return data;
                })
                .then((data) => {
                    getProblemSolutions(data.xcsp as number)
                        .then((solutions) => {
                            setProblemSolutions(solutions);
                        })
                        .catch((err) => {
                            console.error(err.message);
                        });
                    return data;
                })
                .catch((err) => {
                    console.error(err.message);
                });
        }
    }, [id]);

    const refreshSolutions = () => {
        if (!!xcsp) {
            getProblemSolutions(xcsp.id)
                .then((solutions) => {
                    setProblemSolutions(solutions);
                })
                .catch((err) => {
                    console.error(err.message);
                });
        }
    };

    const editorChanged = (valid: boolean, src?: string) => {
        if (!!xcsp) {
            if (src !== undefined) {
                xcsp.src = src;
                setXcsp(xcsp);
                setUnsavedChanges(true);
            }

            setValidXcsp(valid);
        } else {
            console.error("trying to save unloaded document...");
        }
    };

    const save = () => {
        if (!!xcsp) {
            updateXcspInstance(xcsp.id, xcsp).then(() => {
                setUnsavedChanges(false);
                refreshSolutions();
            });
        }
    };

    const solve = (data: RunSolverData) => {
        submitSolve(data)
            .then(() => {
                getProblemSolutions(data.xcsp)
                    .then((solutions) => {
                        setProblemSolutions(solutions);
                    })
                    .catch((err) => {
                        console.error(err.message);
                    });
            })
            .catch((err) => {
                console.error(err.message);
            });
        setShowSolveDialog(false);
    };

    if (!!xcsp && problemDetails) {
        return (
            <div>
                <Box sx={{ flexGrow: 1, paddingBottom: "15px" }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection={{ xs: "column", sm: "row" }}
                        sx={{ fontSize: "12px" }}
                    >
                        <Grid sx={{ order: { xs: 2, sm: 1 } }}>
                            <Typography variant="h5">
                                {"XCSP"}: {problemDetails.name}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            columnSpacing={1}
                            sx={{ order: { xs: 1, sm: 2 } }}
                        >
                            <Grid>
                                <Button
                                    variant="contained"
                                    color={"primary"}
                                    disabled={!unsavedChanges}
                                    onClick={save}
                                >
                                    Save
                                </Button>
                            </Grid>

                            <Grid>
                                <Button
                                    variant="contained"
                                    color={"primary"}
                                    disabled={unsavedChanges || !validXcsp}
                                    onClick={() => setShowSolveDialog(true)}
                                >
                                    Solve
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Typography variant="h5" style={{ marginTop: "15px" }}>
                    Problem Description
                </Typography>
                <Card>
                    <XcspEditor
                        xcsp={xcsp.src}
                        onChange={editorChanged}
                    ></XcspEditor>
                </Card>
                <Typography variant="h5" style={{ marginTop: "15px" }}>
                    External Update
                </Typography>
                <Card sx={{ flexGrow: 1 }}>
                    <CodeMirror
                        value={`curl -X 'PATCH' \\
  '${BACKEND_URL}api/v1/xcsp/${id}/' \\
  -H 'accept: application/json' \\
  -H 'Content-Type: application/json' \\
  -H 'Authorization: Token ${getAccessToken()}' \\
  -d '{
  "src": "XCSP_STRING"
}'`}
                        theme={vscodeDark}
                        readOnly={true}
                    ></CodeMirror>
                </Card>
                <SolutionsTable
                    solutions={problemSolutions}
                    refreshClicked={refreshSolutions}
                ></SolutionsTable>
                <SolveXcspDialog
                    xcspId={xcsp.id}
                    displayed={showSolveDialog}
                    cancel={() => {
                        setShowSolveDialog(false);
                    }}
                    create={(x) => solve(x)}
                ></SolveXcspDialog>
            </div>
        );
    } else {
        return <></>;
    }
};
