export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getAccessToken = () => {
    return localStorage.getItem("accessToken");
};

const updateOptions = (options?: {
    [key: string]: any;
}): { [key: string]: any } => {
    const token = getAccessToken();
    const updatedOptions = {
        headers: { Authorization: `Token ${token}` },
        ...options,
    };
    return updatedOptions;
};

// wrapped up the fetch interface with an effective base URL and
// authentication tokens
export const authenticatedFetch = (
    url: string,
    options?: { [key: string]: any }
) => {
    return fetch(`${BACKEND_URL}${url}`, updateOptions(options));
};
