import { authenticatedFetch } from "./shared";

export interface ValueCreate {
    data: object;
}

export interface Value extends ValueCreate {
    id: number;
    data: object;
}

export interface ProblemDataSummary {
    id: number;
    name: string;
    details: string;
    schema: object;
}

export interface ProblemData {
    id: number;
    name: string;
    details: string;
    schema: object;
    value: Value;
}

export interface ProblemDataCreate {
    name: string;
    details: string;
    schema: object;
    value: ValueCreate;
}

export interface ProblemDataUpdate {
    id: number;
    name?: string;
    details?: string;
    schema?: object;
    value?: Value;
}

export const getProblemDataSets = (): Promise<ProblemDataSummary[]> => {
    return authenticatedFetch(`api/v1/problem_datasets/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json.list;
        })
        .catch(() => {});
};

export const getProblemData = (id: string): Promise<ProblemData> => {
    return authenticatedFetch(`api/v1/problem_datasets/${id}/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};

export const updateProblemData = (data: ProblemDataUpdate): Promise<void> => {
    return authenticatedFetch(`api/v1/problem_datasets/${data.id}/`, {
        method: "PATCH",

        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                debugger;
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const createProblemData = (
    data: ProblemDataCreate
): Promise<ProblemData> => {
    return authenticatedFetch(`api/v1/problem_datasets/`, {
        method: "POST",

        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const deleteProblemData = (id: number): Promise<void> => {
    return authenticatedFetch(`api/v1/problem_datasets/${id}/`, {
        method: "DELETE",
    })
        .then((response) => {
            if (response.ok) {
                return;
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

// export const getProblemValue = (id: number): Promise<Value> => {
//     return authenticatedFetch(`api/v1/problem_values/${id}/`)
//         .then((response) => {
//             if (response.ok) {
//                 return response.json();
//             } else {
//                 window.location.href = "/login";
//             }
//         })
//         .then((json) => {
//             return json;
//         })
//         .catch(() => {});
// };

// export const updateProblemValue = (data: Value): Promise<void> => {
//     return authenticatedFetch(`api/v1/problem_values/${data.id}/`, {
//         method: "PATCH",

//         body: JSON.stringify(data),
//     })
//         .then((response) => {
//             if (response.ok) {
//                 return response.json();
//             } else {
//                 debugger;
//                 window.location.href = "/login";
//             }
//         })
//         .catch(() => {});
// };
