import { authenticatedFetch } from "./shared";

export type SolutionStatus = "SATISFIED" | "PENDING" | "RUNNING";

export type SolverType = "ACE" | "PTO" | "TSP";

export interface XcspSolution {
    id: number;
    problemId: string;
    status: SolutionStatus;
    solution?: object;
    score?: number;
    solver: string;
    error_message?: string;
}

export interface RunSolverData {
    xcsp: number;
    solver: SolverType;
    timeout: number;
}

export const getProblemSolutions = (xcspId: number) => {
    return authenticatedFetch(`api/v1/xcsp_solutions/?xcsp=${xcspId}`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};

export const submitSolve = (data: RunSolverData) => {
    return authenticatedFetch(`api/v1/xcsp/solve/`, {
        method: "POST",
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};
