import React, { useState, useEffect } from "react";

import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";

import { useParams } from "react-router-dom";

import { getProblemInstanceNode, getSolution, Webhook } from "../backendApis";
import { JsonEditor } from "../component/JsonEditor";
import { jsonToFormattedString } from "../utils";

export const ProblemInstanceNodePage = () => {
    let { id } = useParams();

    const [name, setName] = useState("");
    const [solution, setSolution] = useState("");
    const [missingData, setMissingData] = useState("");
    const [outputSchema, setOutputSchema] = useState("");
    const [webhooks, setWebhooks] = useState<Webhook[]>([]);

    useEffect(() => {
        if (!!id) {
            getProblemInstanceNode(id)
                .then((data) => {
                    setWebhooks(data.webhooks);
                    setMissingData(jsonToFormattedString(data.missing_data));

                    if (!!data.data_transformation) {
                        setOutputSchema(
                            jsonToFormattedString(
                                data.data_transformation?.output_schema
                            )
                        );
                        setName(data.data_transformation.name + " Solution");
                    }

                    if (!!data.standard_problem) {
                        setOutputSchema(
                            jsonToFormattedString(
                                data.standard_problem.solution_schema
                            )
                        );
                        setName(data.standard_problem.name + " Solution");
                    }

                    if (!!data.custom_problem) {
                        setOutputSchema(
                            jsonToFormattedString(
                                data.custom_problem.solution_schema
                            )
                        );
                        setName(data.custom_problem.name + " Solution");
                    }

                    return data.best_solution;
                })
                .then((solution_id) => {
                    if (!!solution_id) {
                        getSolution(solution_id).then((solution) => {
                            setSolution(jsonToFormattedString(solution.data));
                        });
                    }
                })
                .catch((err) => {
                    console.error(err.message);
                });
        }
    }, []);

    const maxEditorHeight = "512px";

    return (
        <div>
            <Typography variant="h5">{name}</Typography>

            <div
                style={{
                    display: "flex",
                    flexFlow: "row",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        padding: "0px",
                        marginRight: "10px",
                    }}
                >
                    <Typography variant="h6" style={{ marginTop: "15px" }}>
                        Schema
                    </Typography>
                    <JsonEditor
                        readOnly={true}
                        json={outputSchema}
                        height={maxEditorHeight}
                    />
                </div>
                <div
                    style={{
                        width: "100%",
                        padding: "0px",
                        marginLeft: "10px",
                    }}
                >
                    {solution !== "" && (
                        <div>
                            <Typography
                                variant="h6"
                                style={{ paddingTop: "15px" }}
                            >
                                Solution
                            </Typography>
                            <JsonEditor
                                readOnly={true}
                                json={solution}
                                height={maxEditorHeight}
                            />{" "}
                        </div>
                    )}

                    {missingData !== "{}" && (
                        <>
                            <Typography
                                variant="h6"
                                style={{ paddingTop: "15px" }}
                            >
                                No Solution: Missing Data
                            </Typography>
                            <JsonEditor
                                readOnly={true}
                                json={missingData}
                                height={maxEditorHeight}
                            />
                        </>
                    )}

                    {solution !== "" && missingData !== "{}" && (
                        <div>No solution.</div>
                    )}
                </div>
            </div>

            <Typography variant="h6" style={{ paddingTop: "15px" }}>
                Webhooks
            </Typography>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Endpoint</TableCell>
                            <TableCell>Headers</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {webhooks.map((webhook) => (
                            <TableRow key={webhook.url}>
                                <TableCell component="th" scope="row">
                                    {webhook.url}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                ></TableCell>
                                <TableCell align="right">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <IconButton
                                            aria-label="fire"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <LocalFireDepartmentIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
