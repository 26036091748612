import React, { useEffect } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";

import {
    CreateCustomProblemTypeData,
    CustomProblemTypeType,
} from "../backendApis";

export const CreateProblemDescriptionDialog = ({
    displayed,
    cancel,
    create,
    name,
}: {
    displayed: boolean;
    cancel: () => void;
    create: (data: CreateCustomProblemTypeData) => void;
    name: string;
}) => {
    const [open, setOpen] = React.useState(displayed);

    const [problemData, setProblemData] =
        React.useState<CreateCustomProblemTypeData>({
            name: "",
            details: "",
            type: "XCSP",
            input_data_schema: {},
            solution_schema: {},
        });

    const [problemType, setProblemType] =
        React.useState<CustomProblemTypeType>("XCSP");

    useEffect(() => {
        setOpen(displayed);
    }, [displayed]);

    const handleClose = (created: boolean) => {
        setOpen(false);

        if (created) {
            // bit of a hack, I can't get the Select input to
            // play nicely with state fields
            problemData.type = problemType;
            create(problemData);
        } else {
            cancel();
        }
    };

    return (
        <Dialog open={open} onClose={() => handleClose(false)}>
            <DialogTitle>{`Create ${name}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`Create a new ${name}`}</DialogContentText>
                <FormControl sx={{ m: 1, width: "90%" }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="problemName"
                        label="Name"
                        fullWidth
                        variant="standard"
                        onChange={(event) => {
                            problemData.name = event.target.value;
                            setProblemData(problemData);
                        }}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: "90%" }}>
                    <TextField
                        multiline
                        rows={2}
                        // maxRows={Infinity}
                        margin="dense"
                        id="problemDetails"
                        label="Details"
                        fullWidth
                        variant="standard"
                        onChange={(event) => {
                            problemData.details = event.target.value;
                            setProblemData(problemData);
                        }}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: "90%" }}>
                    <InputLabel htmlFor="demo-dialog-native">
                        Problem Language
                    </InputLabel>
                    <Select
                        native
                        value={problemType}
                        onChange={(event: SelectChangeEvent) => {
                            setProblemType(
                                event.target.value as CustomProblemTypeType
                            );
                        }}
                        input={
                            <OutlinedInput
                                label="Problem Language"
                                id="demo-dialog-native"
                            />
                        }
                    >
                        <option value={"XCSP"}>XCSP</option>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Cancel</Button>
                <Button onClick={() => handleClose(true)}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};
