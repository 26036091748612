import { CustomProblemTypeData } from "./customProblemTypes";
import { DataTransformation } from "./dataTransformation";
import { ProblemDataSummary } from "./problemData";
import { authenticatedFetch } from "./shared";
import { StandardProblemTypeData } from "./standardProblemTypes";

export type ProblemInstanceNodeType =
    | "PROBLEM_DATA"
    | "STANDARD_PROBLEM"
    | "CUSTOM_PROBLEM"
    | "DATA_TRANSFORMATION";

export type ProblemInstanceNodeStatus =
    | "PENDING"
    | "SOLVING"
    | "OPTIMUM"
    | "SATISFIED"
    | "UNSATISFIABLE"
    | "ERROR"
    | "UNSOLVED";

export interface Solution {
    id: number;
    status: string;
    data: object;
    score: number | null;
    time: number | null;
}

export interface WebhookCreate {
    url: string;
}

export interface Webhook extends WebhookCreate {
    id: number;
}

// sending local graph node to the backend
export interface CreateUpdateProblemInstanceNode {
    reference: string; // this is the local node ID, not the ID stored in the DB
    type: ProblemInstanceNodeType;
    problem_data?: number;
    data_transformation?: number;
    standard_problem?: number;
    custom_problem?: number;
    x_position: number;
    y_position: number;
    webhooks: WebhookCreate[];
}

export interface CreateProblemInstanceNode {
    problem_instance: number;
    type: ProblemInstanceNodeType;
    problem_data?: number;
    data_transformation?: number;
    standard_problem?: number;
    custom_problem?: number;
    x_position: number;
    y_position: number;
    webhooks: WebhookCreate[];
}

export interface UpdateProblemInstanceNode {
    id: number;
    problem_instance?: number;
    type?: ProblemInstanceNodeType;
    problem_data?: number;
    data_transformation?: number;
    standard_problem?: number;
    custom_problem?: number;
    x_position?: number;
    y_position?: number;
    webhooks?: WebhookCreate[];
}

// receiving graph node from the front end
export interface ProblemInstanceNode {
    id: number;
    type: ProblemInstanceNodeType;
    problem_data?: ProblemDataSummary;
    data_transformation?: DataTransformation;
    standard_problem?: StandardProblemTypeData;
    custom_problem?: CustomProblemTypeData;
    best_solution?: number;
    status: ProblemInstanceNodeStatus;
    x_position: number;
    y_position: number;
    webhooks: Webhook[];
    missing_data: object;
}

// sending local graph connection to the backend
export interface CreateProblemInstanceConnection {
    problem_instance: number;
    source: number; // the reference of a CreateUpdateProblemInstanceNode
    target: number; // the reference of a CreateUpdateProblemInstanceNode
}

// receiving graph connection from the front end
export interface ProblemInstanceConnection {
    id: number;
    problem_instance: number;
    source: number;
    target: number;
}

export interface CreateProblemInstance {
    name: string;
    details: string;
}

// receiving graph from the front end
export interface ProblemInstance extends CreateProblemInstance {
    id: number;
    nodes: ProblemInstanceNode[];
    connections: ProblemInstanceConnection[];
    status: string;
    solving: boolean;
}

// sending local graph to the backend
export interface ProblemInstanceUpdate {
    id: number;
    name?: string;
    details?: string;
    nodes?: CreateUpdateProblemInstanceNode[];
    connections?: CreateProblemInstanceConnection[];
}

export const createProblemInstance = (
    data: CreateProblemInstance
): Promise<ProblemInstance> => {
    return authenticatedFetch(`api/v1/problem_instances/`, {
        method: "POST",
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const getProblemInstances = (): Promise<ProblemInstance[]> => {
    return authenticatedFetch(`api/v1/problem_instances/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json.list;
        })
        .catch(() => {});
};

export const getProblemInstance = (id: string): Promise<ProblemInstance> => {
    return authenticatedFetch(`api/v1/problem_instances/${id}/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};

export const updateProblemInstance = (
    data: ProblemInstanceUpdate
): Promise<ProblemInstance> => {
    return authenticatedFetch(`api/v1/problem_instances/${data.id}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const solveProblemInstance = (id: number): Promise<ProblemInstance> => {
    return authenticatedFetch(`api/v1/problem_instances/solve/`, {
        method: "POST",
        body: JSON.stringify({ id }),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const createProblemInstanceNode = (
    data: CreateProblemInstanceNode
): Promise<ProblemInstanceNode> => {
    return authenticatedFetch(`api/v1/problem_instance_nodes/`, {
        method: "POST",
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const updateProblemInstanceNode = (
    data: UpdateProblemInstanceNode
): Promise<ProblemInstanceNode> => {
    return authenticatedFetch(`api/v1/problem_instance_nodes/${data.id}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const getProblemInstanceNode = (
    id: string
): Promise<ProblemInstanceNode> => {
    return authenticatedFetch(`api/v1/problem_instance_nodes/${id}/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};

export const removeProblemInstanceNode = (id: number): Promise<void> => {
    return authenticatedFetch(`api/v1/problem_instance_nodes/${id}/`, {
        method: "DELETE",
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const getSolution = (id: number): Promise<Solution> => {
    return authenticatedFetch(`api/v1/solutions/${id}/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};

export const createProblemInstanceConnection = (
    data: CreateProblemInstanceConnection
): Promise<ProblemInstanceConnection> => {
    return authenticatedFetch(`api/v1/problem_instance_connections/`, {
        method: "POST",
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const removeProblemInstanceConnection = (id: number): Promise<void> => {
    return authenticatedFetch(`api/v1/problem_instance_connections/${id}/`, {
        method: "DELETE",
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const updateProblemInstanceConnection = (
    data: ProblemInstanceConnection
): Promise<ProblemInstanceConnection> => {
    return authenticatedFetch(
        `api/v1/problem_instance_connections/${data.id}/`,
        {
            method: "PATCH",
            body: JSON.stringify(data),
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};
