import React from "react";

import {
    Collapse,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import { XcspSolution } from "../backendApis";
import XcspEditor from "./XcspEditor";
import { JsonEditor } from "./JsonEditor";
import RefreshIcon from "@mui/icons-material/Refresh";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";
import { jsonToFormattedString } from "../utils";

const SolutionsRow = ({ solution }: { solution: XcspSolution }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    "& > *": {
                        borderBottom: "unset",
                    },
                    cursor: "pointer",
                }}
                onClick={() => setOpen(!open)}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {solution.status}
                </TableCell>
                <TableCell component="th" scope="row">
                    {solution.solver}
                </TableCell>
                <TableCell component="th">{solution.score}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {!!solution.solution ? (
                            <JsonEditor
                                readOnly={true}
                                json={jsonToFormattedString(solution.solution)}
                            ></JsonEditor>
                        ) : (
                            <div>
                                {!!solution.error_message
                                    ? solution.error_message
                                    : "No Solution"}
                            </div>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export const SolutionsTable = ({
    solutions,
    refreshClicked,
}: {
    solutions: XcspSolution[];
    refreshClicked: () => void;
}) => {
    return (
        <div>
            <Box sx={{ flexGrow: 1, paddingTop: "30px" }}>
                <Grid container justifyContent="space-between">
                    <Grid>
                        <Typography variant="h5">Solutions</Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            aria-label="refresh"
                            color="primary"
                            size="large"
                            onClick={refreshClicked}
                        >
                            <RefreshIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="10%" />
                            <TableCell>Status</TableCell>
                            <TableCell>Solver</TableCell>
                            <TableCell>Score</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {solutions.map((solution: XcspSolution) => (
                            <SolutionsRow
                                key={solution.id}
                                solution={solution}
                            ></SolutionsRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
