import { authenticatedFetch } from "./shared";

export interface Xcsp {
    id: number;
    src: string;
}

export const getXcspInstance = (id: number) => {
    return authenticatedFetch(`api/v1/xcsp/${id}/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};

export const updateXcspInstance = (id: number, data: Xcsp) => {
    return authenticatedFetch(`api/v1/xcsp/${id}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};
