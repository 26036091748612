import React, { useState, useEffect } from "react";

import {
    Fab,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CreateProblemDescriptionDialog } from "../component/CreateProblemDescriptionDialog";
import {
    createCustomProblemType,
    CreateCustomProblemTypeData,
    getCustomProblems,
    CustomProblemTypeData,
} from "../backendApis";
import { plural } from "../utils";

interface CustomProblemsPageArgs {
    name: string;
}

export const CustomProblemsPage = (args: CustomProblemsPageArgs) => {
    const [problems, setProblems] = useState<CustomProblemTypeData[]>([]);

    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);

    useEffect(() => {
        getCustomProblems()
            .then((data) => {
                setProblems(data);
            })
            .catch((err) => {
                console.error(err.message);
            });
    }, []);

    const navigate = useNavigate();
    const handleClick = (data: CustomProblemTypeData) => {
        if (data.type === "XCSP") {
            navigate(`/xcsp-description/${data.id}`);
        }
    };

    const createProblemDescriptionClicked = (
        data: CreateCustomProblemTypeData
    ) => {
        createCustomProblemType(data)
            .then((_) => {
                getCustomProblems()
                    .then((data) => {
                        setProblems(data);
                    })
                    .catch((err) => {
                        console.error(err.message);
                    });
            })
            .catch(() => {
                console.error("error creating description");
            })
            .finally(() => {
                setShowCreateDialog(false);
            });
    };

    return (
        <div>
            <Typography variant="h5" style={{ marginBottom: "15px" }}>
                {plural(args.name)}
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Language</TableCell>
                            {/* <TableCell>Valid</TableCell> */}
                            <TableCell>Details</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {problems.map((problemDescription) => (
                            <TableRow
                                key={problemDescription.id}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                    cursor: "pointer",
                                }}
                                onClick={() => handleClick(problemDescription)}
                            >
                                <TableCell component="th" scope="row">
                                    {problemDescription.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {problemDescription.type}
                                </TableCell>
                                <TableCell>
                                    {problemDescription.details}
                                </TableCell>
                                <TableCell align="right">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Fab
                color="primary"
                aria-label="add"
                sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2),
                }}
                onClick={() => {
                    setShowCreateDialog(true);
                }}
            >
                <AddIcon />
            </Fab>
            <CreateProblemDescriptionDialog
                displayed={showCreateDialog}
                cancel={() => {
                    setShowCreateDialog(false);
                }}
                create={createProblemDescriptionClicked}
                name={args.name}
            ></CreateProblemDescriptionDialog>
        </div>
    );
};
