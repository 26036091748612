import React, { useEffect } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";

import {
    CreateProblemInstanceNode,
    createProblemInstanceNode,
    getStandardProblemTypes,
    ProblemInstanceNode,
    StandardProblemTypeData,
    WebhookCreate,
} from "../../backendApis";

export interface AddStandardProblem {
    problem: StandardProblemTypeData;
    webhooks: WebhookCreate[];
}

export const AddStandardProblemTypeDialog = ({
    problemInstanceId,
    displayed,
    cancel,
    create,
    name,
}: {
    problemInstanceId: number;
    displayed: boolean;
    cancel: () => void;
    create: (node: ProblemInstanceNode) => void;
    name: string;
}) => {
    const [open, setOpen] = React.useState(displayed);

    const [selectedProblemType, setSelectedProblemType] = React.useState<
        StandardProblemTypeData | undefined
    >(undefined);

    const [webhook, setWebhook] = React.useState("");

    const [problemTypes, setProblemTypes] = React.useState<
        StandardProblemTypeData[]
    >([]);

    useEffect(() => {
        setOpen(displayed);

        getStandardProblemTypes().then((problemTypes) => {
            setProblemTypes(problemTypes);
        });
    }, [displayed]);

    const handleClose = (created: boolean) => {
        if (created === false) {
            setOpen(false);
            cancel();
            return;
        }

        if (!selectedProblemType) {
            return;
        }

        const playLoad: CreateProblemInstanceNode = {
            problem_instance: problemInstanceId,
            type: "STANDARD_PROBLEM",
            standard_problem: selectedProblemType.id,
            x_position: 50,
            y_position: 50,
            webhooks: webhook !== "" ? [{ url: webhook }] : [],
        };

        createProblemInstanceNode(playLoad)
            .then((node) => {
                create(node);
                setSelectedProblemType(undefined);
                setOpen(false);
            })
            .catch((error) => {});
    };

    const programTypeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newProgramTypeId = (event.target as HTMLInputElement).value;
        const newProgramType = problemTypes.find(
            (x) => x.id.toString() === newProgramTypeId
        );
        setSelectedProblemType(newProgramType);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{`Add ${name}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`Select ${name}`}</DialogContentText>
                <FormControl>
                    <RadioGroup
                        // value={problemTypes[0]?.id}
                        onChange={programTypeChanged}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        {problemTypes.map((problemType) => (
                            <FormControlLabel
                                key={problemType.id}
                                value={problemType.id}
                                control={<Radio />}
                                label={problemType.name}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
                <DialogContentText>
                    {selectedProblemType?.details}
                </DialogContentText>
                <TextField
                    value={webhook}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setWebhook(event.target.value);
                    }}
                    style={{ marginTop: "5px" }}
                    fullWidth
                    id="outlined-basic"
                    label="Webhook"
                    helperText="External endpoint to be called when solutions are found. Solutions are posted to the given url with a json data payload containing a 'solution' field."
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Cancel</Button>
                <Button onClick={() => handleClose(true)}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};
