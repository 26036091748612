import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { lintGutter } from "@codemirror/lint";
import { EditorView, lineNumbers } from "@codemirror/view";
import { json } from "@codemirror/lang-json";
import { linter, Diagnostic } from "@codemirror/lint";
import Ajv from "ajv";
import { getErrorDiagnostics } from "./XmlLinter";

import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { ViewUpdate } from "@codemirror/view";
import { EditorState } from "@codemirror/state";

interface JsonSchemaEditorProps {
    jsonSchema?: string;
    onChange?: (valid: boolean, src?: string) => void;
    readOnly?: boolean;
    height?: string;
}

const jsonSchemaDiagnostics = (view: EditorView): Diagnostic[] => {
    const ajv = new Ajv();

    try {
        const jsonSchema: any = JSON.parse(view.state.doc.toString());
        const validJonSchema = ajv.validateSchema(jsonSchema);

        if (!validJonSchema && !!ajv.errors) {
            const diagnostics: Diagnostic[] = ajv.errors.map((error) => {
                const path =
                    error.instancePath === ""
                        ? "root object"
                        : error.instancePath;

                const paramsString = Object.values(error.params).map((v) =>
                    v.toString()
                );

                const message = `${path} ${error.message}: ${paramsString}`;

                return {
                    from: 0,
                    message: message,
                    severity: "error",
                    to: 0,
                };
            });

            return diagnostics;
        }
    } catch (e) {
        if (!(e instanceof SyntaxError)) throw e;
        const pos = getErrorPosition(e, view.state.doc);
        return [
            {
                from: pos,
                message: e.message,
                severity: "error",
                to: pos,
            },
        ];
    }

    return [];
};

const getErrorPosition = (error: SyntaxError, doc: any): number => {
    let m;
    if ((m = error.message.match(/at position (\d+)/)))
        return Math.min(+m[1], doc.length);
    if ((m = error.message.match(/at line (\d+) column (\d+)/)))
        return Math.min(doc.line(+m[1]).from + +m[2] - 1, doc.length);
    return 0;
};

export const JsonSchemaEditor = ({
    jsonSchema,
    onChange,
    readOnly,
    height,
}: JsonSchemaEditorProps) => {
    const onChangeInternal = (jsonSchema: string, viewUpdate: ViewUpdate) => {
        const errors = getErrorDiagnostics(
            jsonSchemaDiagnostics(viewUpdate.view)
        );
        const valid = errors.length === 0;

        if (!!onChange) {
            onChange(valid, jsonSchema);
        }
    };

    const onUpdateInternal = (viewUpdate: ViewUpdate) => {
        if (!!onChange) {
            const diagnostics = jsonSchemaDiagnostics(viewUpdate.view);
            const valid = diagnostics.length === 0;
            onChange(valid);
        }
    };

    const onCreateEditor = (view: EditorView, state: EditorState) => {
        if (!!onChange) {
            const diagnostics = jsonSchemaDiagnostics(view);
            const valid = diagnostics.length === 0;
            onChange(valid);
        }
    };

    return (
        <CodeMirror
            value={jsonSchema}
            height={height}
            extensions={[
                json(),
                linter(jsonSchemaDiagnostics),
                lineNumbers({ formatNumber: (x) => (x - 1).toString() }), // change code mirrors line number to index at zero
                lintGutter({}), // show linting error in gutter
            ]}
            onCreateEditor={onCreateEditor}
            onChange={onChangeInternal}
            onUpdate={onUpdateInternal}
            theme={vscodeDark} // select a theme from here: https://uiwjs.github.io/react-codemirror/#/theme/home
            readOnly={readOnly}
        />
    );
};
