import { Card, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
    getStandardProblemType,
    StandardProblemTypeData,
} from "../backendApis";

import { JsonEditor } from "../component/JsonEditor";
import { jsonToFormattedString } from "../utils";

export const StandardProblemPage = () => {
    let { id } = useParams();

    const [problem, setProblem] = useState<StandardProblemTypeData | null>(
        null
    );

    useEffect(() => {
        if (!!id) {
            getStandardProblemType(id)
                .then((data) => {
                    setProblem(data);
                })
                .catch((err) => {
                    console.error(err.message);
                });
        }
    }, [id]);

    const maxEditorHeight = "calc(100vh - 260px)";

    if (!!problem) {
        return (
            <div>
                <Typography variant="h5">{problem.name}</Typography>
                <p>{problem.details}</p>

                <div
                    style={{
                        display: "flex",
                        flexFlow: "row",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            padding: "0px",
                            marginRight: "10px",
                        }}
                    >
                        <Typography variant="h5">Input Data Schema</Typography>
                        <Card>
                            <JsonEditor
                                height={maxEditorHeight}
                                readOnly={true}
                                json={jsonToFormattedString(
                                    problem.input_data_schema
                                )}
                            />
                        </Card>
                    </div>
                    <div
                        style={{
                            width: "100%",
                            padding: "0px",
                            marginLeft: "10px",
                        }}
                    >
                        <Typography variant="h5">Solution Schema</Typography>
                        <Card>
                            <JsonEditor
                                height={maxEditorHeight}
                                readOnly={true}
                                json={jsonToFormattedString(
                                    problem.solution_schema
                                )}
                            />
                        </Card>
                    </div>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};
