import { BACKEND_URL } from "./shared";

export const login = async (username: string, password: string) => {
    const response = await fetch(`${BACKEND_URL}api-token-auth/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            username,
            password,
        }),
    })
        .then((res) => {
            if (res.ok) {
                res.json().then(({ token }) => {
                    localStorage.setItem("accessToken", token);
                });
                return { success: true };
            } else {
                return { success: false };
            }
        })
        .catch((error) => {
            return {
                success: false,
            };
        });

    return response;
};
