import React, { useState, useEffect } from "react";

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import {
    getStandardProblemTypes,
    StandardProblemTypeData,
} from "../backendApis";

interface StandardProblemsPageArgs {
    name: string;
}

export const StandardProblemsPage = (args: StandardProblemsPageArgs) => {
    const [problems, setProblems] = useState<StandardProblemTypeData[]>([]);

    useEffect(() => {
        getStandardProblemTypes()
            .then((data) => {
                setProblems(data);
            })
            .catch((err) => {
                console.error(err.message);
            });
    }, []);

    const navigate = useNavigate();
    const handleClick = (data: StandardProblemTypeData) => {
        navigate(`/known-problems/${data.id}`);
    };

    return (
        <div>
            <Typography variant="h5" style={{ marginBottom: "15px" }}>
                {args.name}
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {problems.map((problem) => (
                            <TableRow
                                key={problem.id}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                    cursor: "pointer",
                                }}
                                onClick={() => handleClick(problem)}
                            >
                                <TableCell component="th" scope="row">
                                    {problem.name}
                                </TableCell>

                                <TableCell>{problem.details}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
