import { v4 as uuidv4 } from "uuid";

import { Edge, Node } from "reactflow";

import {
    CreateUpdateProblemInstanceNode,
    ProblemInstanceConnection,
    ProblemInstanceNode,
    UpdateProblemInstanceNode,
} from "../../backendApis";
import { NodeData } from "./nodeData";

// return the diagram node id from a given ProblemInstanceNode
export const problemInstanceNodeToNodeId = (n: ProblemInstanceNode): string => {
    // return `${n.type}_${n.id}`;
    return `${n.id}`;
};

// translate a given ProblemInstanceConnection to a diagram connection
export const problemInstanceConnectionToEdge = (
    connection: ProblemInstanceConnection
): Edge => {
    const source = connection.source.toString();
    const target = connection.target.toString();

    return {
        id: connection.id.toString(),
        source,
        target,
    };
};

// translate a diagram node into a ProblemInstanceNode
export const nodeToCreateProblemInstanceNode = (
    n: Node<NodeData>
): CreateUpdateProblemInstanceNode => {
    return {
        reference: n.id,
        type: n.data.type,
        problem_data: n.data.problemData?.id,
        standard_problem: n.data.standardProblem?.id,
        custom_problem: n.data.customProblem?.id,
        data_transformation: n.data.dataTransformation?.id,
        x_position: n.position.x,
        y_position: n.position.y,
        webhooks: n.data.webhooks,
    };
};

export const nodeToUpdateProblemInstanceNode = (
    n: Node<NodeData>
): UpdateProblemInstanceNode => {
    return {
        id: Number(n.id),
        type: n.data.type,
        problem_data: n.data.problemData?.id,
        standard_problem: n.data.standardProblem?.id,
        custom_problem: n.data.customProblem?.id,
        data_transformation: n.data.dataTransformation?.id,
        x_position: n.position.x,
        y_position: n.position.y,
        webhooks: n.data.webhooks,
    };
};

// translate a given ProblemInstanceNode to a diagram node
export const problemInstanceNodeToNode = (
    n: ProblemInstanceNode
): Node<NodeData> => {
    const id = problemInstanceNodeToNodeId(n);

    const node = {
        id,
        type: n.type,
        position: {
            x: n.x_position,
            y: n.y_position,
        },
    };

    if (!!n.problem_data) {
        const nodeData: NodeData = {
            id: n.id,
            type: n.type,
            problemData: n.problem_data,
            inputSchema: {},
            outputSchema: n.problem_data.schema,
            validInput: Object.keys(n.missing_data).length === 0,
            solutionStatus: n.status,
            solutionId: n.best_solution,
            webhooks: n.webhooks,
        };

        return { data: nodeData, ...node };
    } else if (!!n.standard_problem) {
        const nodeData: NodeData = {
            id: n.id,
            type: n.type,
            standardProblem: n.standard_problem,
            inputSchema: n.standard_problem.input_data_schema,
            outputSchema: n.standard_problem.solution_schema,
            validInput: Object.keys(n.missing_data).length === 0,
            solutionStatus: n.status,
            solutionId: n.best_solution,
            webhooks: n.webhooks,
        };

        return { data: nodeData, ...node };
    } else if (!!n.custom_problem) {
        const nodeData: NodeData = {
            id: n.id,
            type: n.type,
            customProblem: n.custom_problem,
            inputSchema: n.custom_problem.input_data_schema,
            outputSchema: n.custom_problem.solution_schema,
            validInput: Object.keys(n.missing_data).length === 0,
            solutionStatus: n.status,
            solutionId: n.best_solution,
            webhooks: n.webhooks,
        };

        return { data: nodeData, ...node };
    } else if (!!n.data_transformation) {
        const nodeData: NodeData = {
            id: n.id,
            type: n.type,
            dataTransformation: n.data_transformation,
            inputSchema: n.data_transformation.input_schema,
            outputSchema: n.data_transformation.output_schema,
            validInput: Object.keys(n.missing_data).length === 0,
            solutionStatus: n.status,
            solutionId: n.best_solution,
            webhooks: n.webhooks,
        };

        return { data: nodeData, ...node };
    } else {
        throw Error;
    }
};
