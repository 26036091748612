import { useEffect, useRef } from "react";

// https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
export const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

// https://stackoverflow.com/questions/53179075/with-useeffect-how-can-i-skip-applying-an-effect-upon-the-initial-render
export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

export const jsonToFormattedString = (json: object): string => {
    return JSON.stringify(json, null, 4);
};

// export const debounce = (
//     func: (...args: any) => void,
//     timeout: number = 300
// ) => {
//     let handle: any;
//     return (...args: any) => {
//         clearTimeout(handle);
//         handle = setTimeout(() => {
//             func(args);
//         }, timeout);
//     };
// };

// TODO: this is too basic
export const plural = (s: string) => s + "s";
