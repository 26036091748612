import { authenticatedFetch } from "./shared";

export interface UserDetails {
    username: string;
}

export const getUserDetails = (): Promise<UserDetails> => {
    return authenticatedFetch(`api/v1/loom_user/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};
