import { Handle, Position } from "reactflow";
import {
    CustomProblemTypeData,
    ProblemInstanceNodeStatus,
    StandardProblemTypeData,
} from "../../backendApis";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import UpdateIcon from "@mui/icons-material/Update";

type ProblemTypeNodeArgs = {
    data: {
        standardProblem?: StandardProblemTypeData;
        customProblem?: CustomProblemTypeData;
        validInput: boolean;
        solutionStatus: ProblemInstanceNodeStatus;
    };
    selected: boolean;
};

export const ProblemTypeNode = ({ data, selected }: ProblemTypeNodeArgs) => {
    const borderColor = selected ? "black" : "grey";

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "15px",
                    border: "1px solid #777",
                    borderColor,
                    padding: 10,
                    // width: 150,
                    height: 44,
                    background: "white",
                    cursor: "pointer",
                    borderRadius: "10px",
                    color: "black",
                }}
            >
                <div>
                    {!data.validInput && (
                        <WarningAmberIcon color="warning"></WarningAmberIcon>
                    )}
                    {data.validInput && (
                        <CheckCircleOutlineIcon color="success"></CheckCircleOutlineIcon>
                    )}
                </div>

                <div>
                    {data.standardProblem?.name}
                    {data.customProblem?.name}
                </div>
                <div>
                    {data.solutionStatus === "PENDING" && (
                        <HourglassTopIcon color="info"></HourglassTopIcon>
                    )}
                    {data.solutionStatus === "SOLVING" && (
                        // <CircularProgress color="info" size={20}></CircularProgress>
                        <UpdateIcon color="info"></UpdateIcon>
                    )}
                    {data.solutionStatus === "OPTIMUM" && (
                        <DoneAllIcon color="success"></DoneAllIcon>
                    )}
                    {data.solutionStatus === "SATISFIED" && (
                        <DoneIcon color="success"></DoneIcon>
                    )}
                    {data.solutionStatus === "UNSATISFIABLE" && (
                        <WarningAmberIcon color="warning"></WarningAmberIcon>
                    )}
                    {data.solutionStatus === "ERROR" && (
                        <ErrorOutlineIcon color="error"></ErrorOutlineIcon>
                    )}
                    {data.solutionStatus === "UNSOLVED" && (
                        <HelpOutlineIcon color="info"></HelpOutlineIcon>
                    )}
                </div>
            </div>
            <Handle type="target" position={Position.Left} id="input" />
            <Handle type="source" position={Position.Right} id="output" />
        </div>
    );
};
