import { ElementSpec, AttrSpec } from "@codemirror/lang-xml";
import { linter, Diagnostic } from "@codemirror/lint";
import { EditorView } from "codemirror";

import { XMLValidator } from "fast-xml-parser";
import XmlDoc from "xmldoc";

// extended "@codemirror/lang-xml" AttrSpec to allow for specifying require attributes
// and values can no longe rbe completions
export interface ExtendedAttrSpec extends AttrSpec {
    required?: boolean;
    values?: string[];
}

// extended "@codemirror/lang-xml" ElementSpec to allow for specifying whether text is allowed
// and attributes must be of type ExtendedAttrSpec
export interface ExtendedElementSpec extends ElementSpec {
    attributes: readonly ExtendedAttrSpec[];
    textAllowed?: boolean | "required";
    unknownChildElements?: "info" | "warning" | "error";
    unknownAttributes?: "info" | "warning" | "error";
}

export interface XmlSpec {
    elementSpecs: ExtendedElementSpec[];
    unknownElements?: "info" | "warning" | "error";
}

// returns attribute diagnostics for given spec
const getElementAttributeDiagnostics = (
    element: XmlDoc.XmlElement,
    elementSpec: ExtendedElementSpec
): Diagnostic[] => {
    let attributeDiagnostics: Diagnostic[] = [];

    const elementName = element.name;
    const attributeNames: string[] = Object.keys(element.attr);

    const requiredAttributeNames = elementSpec.attributes
        .filter((att) => att.required !== undefined && att.required)
        .map((x) => x.name);

    // report missing required attributes as an error
    for (const requiredAttributeName of requiredAttributeNames) {
        if (!attributeNames.includes(requiredAttributeName)) {
            attributeDiagnostics.push({
                from: element.position,
                to: element.position,
                severity: "error",
                message: `Required attribute '${requiredAttributeName}' is missing on element '${elementName}'.`,
            });
        }
    }

    if (elementSpec.unknownAttributes !== undefined) {
        for (const attrName of attributeNames) {
            const attrValue = element.attr[attrName];

            const attrSpec = elementSpec.attributes.find(
                (x) => x.name === attrName
            );
            const allowedAttributeNames = elementSpec.attributes.map(
                (x) => x.name
            );

            if (attrSpec === undefined) {
                attributeDiagnostics.push({
                    from: element.position,
                    to: element.position,
                    severity: elementSpec.unknownAttributes,
                    message: `Unknown attribute '${attrName}' on element '${elementName}'. Should be one of ${allowedAttributeNames}`,
                });
            }
            // else if (attrSpec.values == undefined) {
            //     attributeDiagnostics.push({
            //         from: element.position,
            //         to: element.position,
            //         severity: elementSpec.unknownAttributes,
            //         message: `1Invalid attribute '${attrName}' value '${attrValue}' for element '${elementName}'. Should be one of ${attrSpec.values}`,
            //     });
            // }
            else if (!!attrSpec.values && attrSpec.values.length > 0) {
                if (!attrSpec.values.includes(attrValue)) {
                    attributeDiagnostics.push({
                        from: element.position,
                        to: element.position,
                        severity: elementSpec.unknownAttributes,
                        message: `Unknown attribute '${attrName}' value '${attrValue}' for element '${elementName}'. Should be one of ${attrSpec.values}`,
                    });
                }
            }
        }
    }

    return attributeDiagnostics;
};

// returns text diagnostics for given spec
const getElementTextDiagnostics = (
    element: XmlDoc.XmlElement,
    elementSpec: ExtendedElementSpec
): Diagnostic[] => {
    const elementName = element.name;

    const texts = element.children.filter(
        (x) => x instanceof XmlDoc.XmlTextNode && x.text.trim()
    ) as XmlDoc.XmlTextNode[];

    let textsDiagnostics: Diagnostic[] = [];
    if (
        elementSpec.textAllowed !== undefined &&
        elementSpec.textAllowed === false &&
        texts.length > 0
    ) {
        textsDiagnostics.push({
            from: element.position,
            to: element.position,
            severity: "error",
            message: `Element '${elementName}' should not contain any text`,
        });
    }

    if (
        elementSpec.textAllowed !== undefined &&
        elementSpec.textAllowed === "required" &&
        texts.length === 0
    ) {
        textsDiagnostics.push({
            from: element.position,
            to: element.position,
            severity: "error",
            message: `Element '${elementName}' must contain text`,
        });
    }

    return textsDiagnostics;
};

// returns child element diagnostics for given spec
const getElementChildElementDiagnostics = (
    element: XmlDoc.XmlElement,
    elementSpec: ExtendedElementSpec
): Diagnostic[] => {
    const elementName = element.name;

    const childElements = element.children.filter(
        (x) => x instanceof XmlDoc.XmlElement
    ) as XmlDoc.XmlElement[];

    const childElementNames = childElements.map((x) => x.name);

    let childElementDiagnostics: Diagnostic[] = [];
    if (
        elementSpec &&
        elementSpec.children !== undefined &&
        // elementSpec.children.length > 0 &&
        elementSpec.unknownChildElements !== undefined
    ) {
        for (const childElementName of childElementNames) {
            if (!elementSpec.children.includes(childElementName)) {
                childElementDiagnostics.push({
                    from: element.position,
                    to: element.position,
                    severity: elementSpec.unknownChildElements,
                    message: `Unknown child element '${childElementName}' on element '${elementName}'. Should be one of: ${elementSpec.children}`,
                });
            }
        }
    }

    return childElementDiagnostics;
};

// returns element diagnostics for given spec
const getElementDiagnostics = (
    element: XmlDoc.XmlElement,
    xmlSpec: XmlSpec
): Diagnostic[] => {
    const elementName = element.name;
    const elementSpec = xmlSpec.elementSpecs.find(
        (x) => x.name === elementName
    );

    if (elementName === "instance") {
        // console.log(element);
        // console.log(elementSpec);
        // console.log("------------");
        // // debugger;
    }

    if (elementSpec === undefined) {
        if (xmlSpec.unknownElements !== undefined) {
            return [
                {
                    from: element.position,
                    to: element.position,
                    severity: xmlSpec.unknownElements,
                    message: `Unknown element ${elementName}`,
                },
            ];
        } else {
            return [];
        }
    } else {
        let textsDiagnostics = getElementTextDiagnostics(element, elementSpec);
        let attributeDiagnostics = getElementAttributeDiagnostics(
            element,
            elementSpec
        );
        let childElementDiagnostics = getElementChildElementDiagnostics(
            element,
            elementSpec
        );

        // apply getElementDiagnostics recursively through child elements
        const childDiagnostics = element.children
            .filter((x) => x instanceof XmlDoc.XmlElement)
            .map((child: any) => getElementDiagnostics(child, xmlSpec))
            .flat();

        return attributeDiagnostics.concat(
            textsDiagnostics,
            childElementDiagnostics,
            childDiagnostics
        );
    }
};

export const getErrorDiagnostics = (
    diagnostics: Diagnostic[]
): Diagnostic[] => {
    return diagnostics.filter((x) => x.severity === "error");
};

export const getXmlDiagnostics = (
    xmlSpec: XmlSpec,
    view: EditorView
): Diagnostic[] => {
    let diagnostics: Diagnostic[] = [];

    const text = view.state.doc.toJSON().join("");

    if (text === "") {
        return [
            {
                from: 0,
                to: 0,
                severity: "error",
                message: "Empty document",
            },
        ];
    }

    try {
        const rootXmlElement = new XmlDoc.XmlDocument(text);
        const rootElementSpecs = xmlSpec.elementSpecs.filter((x) => x.top);

        const validRootElement = rootElementSpecs
            .map((elem) => elem.name === rootXmlElement.name)
            .some((x) => x);

        if (!validRootElement) {
            diagnostics.push({
                from: rootXmlElement.position,
                to: rootXmlElement.position,
                severity: "error",
                message: "Unknown root element",
            });
        }

        diagnostics = diagnostics.concat(
            getElementDiagnostics(rootXmlElement, xmlSpec)
        );
    } catch (err) {}

    const xmlFormattingErrors = XMLValidator.validate(text);

    if (xmlFormattingErrors !== true) {
        // https://codemirror.net/docs/migration/ see positions section
        diagnostics.push({
            from:
                xmlFormattingErrors.err.col + xmlFormattingErrors.err.line + 1,
            to: xmlFormattingErrors.err.col + xmlFormattingErrors.err.line + 1,
            severity: "error",
            message: xmlFormattingErrors.err.msg,
        });
    }

    return diagnostics;
};
// constructs a code mirror xml linter from given element specification
export const makeXmlLinter = (xmlSpec: XmlSpec) =>
    linter((view: EditorView): Diagnostic[] => {
        return getXmlDiagnostics(xmlSpec, view);
    });
