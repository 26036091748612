import { Handle, Position } from "reactflow";
import { ProblemDataSummary } from "../../backendApis";

type DataSourceNodeArgs = {
    data: {
        problemData: ProblemDataSummary;
    };
    selected: boolean;
};

export const DataSourceNode = ({ data, selected }: DataSourceNodeArgs) => {
    const borderColor = selected ? "black" : "grey";

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #777",
                    borderColor,
                    padding: 10,
                    minWidth: 150,
                    height: 40,
                    background: "white",
                    cursor: "pointer",
                    color: "black",
                }}
            >
                {data.problemData.name}
            </div>

            <Handle type="source" position={Position.Right} id="output" />
        </>
    );
};
