import React, { useEffect } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    TextField,
} from "@mui/material";
import { ProblemDataCreate } from "../backendApis";

const defaultSchema = {
    type: "object",
    properties: {
        foo: {
            type: "integer",
        },
        bar: {
            type: "string",
        },
    },
    required: ["foo", "bar"],
};

const defaultValue = {
    foo: 1,
    bar: "text",
};

export const CreateProblemDescriptionDialog = ({
    displayed,
    cancel,
    create,
    name,
}: {
    displayed: boolean;
    cancel: () => void;
    create: (data: ProblemDataCreate) => void;
    name: string;
}) => {
    const [open, setOpen] = React.useState(displayed);

    const [problemData, setProblemData] = React.useState<ProblemDataCreate>({
        name: "",
        details: "",
        schema: defaultSchema,
        value: {
            data: defaultValue,
        },
    });

    useEffect(() => {
        setOpen(displayed);
    }, [displayed]);

    const handleClose = (created: boolean) => {
        setOpen(false);

        if (created) {
            create(problemData);
        } else {
            cancel();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>{`Create ${name}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`Create a new ${name}`}</DialogContentText>
                <FormControl sx={{ m: 1, width: "90%" }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="problemName"
                        label="Name"
                        fullWidth
                        variant="standard"
                        onChange={(event) => {
                            problemData.name = event.target.value;
                            setProblemData(problemData);
                        }}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: "90%" }}>
                    <TextField
                        multiline
                        rows={2}
                        // maxRows={Infinity}
                        margin="dense"
                        id="problemDetails"
                        label="Details"
                        fullWidth
                        variant="standard"
                        onChange={(event) => {
                            problemData.details = event.target.value;
                            setProblemData(problemData);
                        }}
                    />
                </FormControl>
                {/* <FormControl sx={{ m: 1, width: "90%" }}>
                    <TextField
                        multiline
                        rows={2}
                        // maxRows={Infinity}
                        margin="dense"
                        id="schema"
                        label="Schema"
                        fullWidth
                        variant="standard"
                        onChange={(event) => {
                            problemData.schema = event.target.value;
                            setProblemData(problemData);
                        }}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: "90%" }}>
                    <TextField
                        multiline
                        rows={2}
                        // maxRows={Infinity}
                        margin="dense"
                        id="data"
                        label="Data"
                        fullWidth
                        variant="standard"
                        onChange={(event) => {
                            problemData.data = event.target.value;
                            setProblemData(problemData);
                        }}
                    />
                </FormControl> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Cancel</Button>
                <Button onClick={() => handleClose(true)}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};
