import { authenticatedFetch } from "./shared";

export type CustomProblemTypeType = "XCSP";

export interface CreateCustomProblemTypeData {
    name: string;
    details: string;
    type: CustomProblemTypeType;
    input_data_schema: object;
    solution_schema: object;
}

export interface CustomProblemTypeData extends CreateCustomProblemTypeData {
    id: number;
    xcsp?: number;
}

export const createCustomProblemType = (
    data: CreateCustomProblemTypeData
): Promise<CustomProblemTypeData> => {
    return authenticatedFetch(`api/v1/custom_problems/`, {
        method: "POST",
        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const getCustomProblems = (): Promise<CustomProblemTypeData[]> => {
    return authenticatedFetch(`api/v1/custom_problems/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json.list;
        })
        .catch(() => {});
};

export const getCustomProblemType = (
    id: string
): Promise<CustomProblemTypeData> => {
    return authenticatedFetch(`api/v1/custom_problems/${id}/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};
