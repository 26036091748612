import React, { useEffect } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";

import {
    createProblemInstanceNode,
    CreateProblemInstanceNode,
    getProblemDataSets,
    ProblemDataSummary,
    ProblemInstanceNode,
} from "../../backendApis";

export const AddDataSourceDialog = ({
    problemInstanceId,
    displayed,
    cancel,
    create,
    name,
}: {
    problemInstanceId: number;
    displayed: boolean;
    cancel: () => void;
    create: (node: ProblemInstanceNode) => void;
    name: string;
}) => {
    const [open, setOpen] = React.useState(displayed);

    const [selectedDataset, setSelectedDataset] = React.useState<
        ProblemDataSummary | undefined
    >(undefined);

    const [datasets, setDatasets] = React.useState<ProblemDataSummary[]>([]);

    useEffect(() => {
        setOpen(displayed);

        getProblemDataSets().then((datasets) => {
            setDatasets(datasets);
        });
    }, [displayed]);

    const handleClose = (created: boolean) => {
        if (created === false) {
            setOpen(false);
            cancel();
            return;
        }

        if (!selectedDataset) {
            return;
        }

        const playLoad: CreateProblemInstanceNode = {
            problem_instance: problemInstanceId,
            type: "PROBLEM_DATA",
            problem_data: selectedDataset.id,
            x_position: 50,
            y_position: 50,
            webhooks: [],
        };

        createProblemInstanceNode(playLoad)
            .then((node) => {
                create(node);
                setOpen(false);
            })
            .catch((error) => {});
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{`${name} Selector`}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`Select a ${name}`}</DialogContentText>
                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <InputLabel htmlFor="demo-dialog-native">{name}</InputLabel>
                    <Select
                        native
                        value={JSON.stringify(selectedDataset)}
                        onChange={(event: SelectChangeEvent) => {
                            const dataset = JSON.parse(event.target.value);
                            if (dataset["id"] !== -1) {
                                setSelectedDataset(dataset);
                            } else {
                                setSelectedDataset(undefined);
                            }
                        }}
                        renderValue={(x) => JSON.parse(x)["name"]}
                        input={
                            <OutlinedInput
                                // label="Data source"
                                id="demo-dialog-native"
                            />
                        }
                    >
                        <option
                            key={-1}
                            value={JSON.stringify({ id: -1, name: "sdfsdf" })}
                        >
                            None
                        </option>
                        {datasets.map((dataset) => (
                            <option
                                key={dataset.id}
                                value={JSON.stringify(dataset)}
                            >
                                {dataset.name}
                            </option>
                        ))}
                        {/* <option value={"ACE"}>ACE</option>
                        <option value={"PTO"}>PTO</option>
                        <option value={"TSP"}>TSP</option> */}
                    </Select>
                </FormControl>
                <DialogContentText>
                    {selectedDataset?.details}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Cancel</Button>
                <Button onClick={() => handleClose(true)}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};
