import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { lintGutter } from "@codemirror/lint";
import { EditorView, lineNumbers } from "@codemirror/view";
import { json as jsonLang } from "@codemirror/lang-json";
import { linter, Diagnostic } from "@codemirror/lint";
import Ajv from "ajv";

import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { ViewUpdate } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { getErrorDiagnostics } from "./XmlLinter";

interface JsonEditorProps {
    json?: string;
    schema?: string;
    onChange?: (valid: boolean, src?: string) => void;
    readOnly?: boolean;
    height?: string;
}

const getErrorPosition = (error: SyntaxError, doc: any): number => {
    let m;
    if ((m = error.message.match(/at position (\d+)/)))
        return Math.min(+m[1], doc.length);
    if ((m = error.message.match(/at line (\d+) column (\d+)/)))
        return Math.min(doc.line(+m[1]).from + +m[2] - 1, doc.length);
    return 0;
};

export const JsonEditor = ({
    json,
    schema,
    onChange,
    readOnly,
    height,
}: JsonEditorProps) => {
    const onChangeInternal = (jsonString: string, viewUpdate: ViewUpdate) => {
        if (!!onChange) {
            const errors = getErrorDiagnostics(
                getJsonDiagnostics(viewUpdate.view)
            );
            const valid = errors.length === 0;

            onChange(valid, jsonString);
        }
    };

    const onUpdateInternal = (viewUpdate: ViewUpdate) => {
        if (!!onChange) {
            const diagnostics = getJsonDiagnostics(viewUpdate.view);
            const valid = diagnostics.length === 0;
            onChange(valid);
        }
    };

    const onCreateEditor = (view: EditorView, state: EditorState) => {
        if (!!onChange) {
            const diagnostics = getJsonDiagnostics(view);
            const valid = diagnostics.length === 0;
            onChange(valid);
        }
    };

    const getJsonDiagnostics = (view: EditorView): Diagnostic[] => {
        const ajv = new Ajv();

        try {
            JSON.parse(view.state.doc.toString());
        } catch (e) {
            if (!(e instanceof SyntaxError)) throw e;
            const pos = getErrorPosition(e, view.state.doc);
            return [
                {
                    from: pos,
                    message: e.message,
                    severity: "error",
                    to: pos,
                },
            ];
        }

        if (!!schema) {
            try {
                const jsonSchema = JSON.parse(schema);
                const json: any = JSON.parse(view.state.doc.toString());
                const validate = ajv.compile(jsonSchema);
                const meetSchema = validate(json);

                if (!meetSchema && !!validate.errors) {
                    const diagnostics: Diagnostic[] = validate.errors.map(
                        (error) => {
                            const path =
                                error.instancePath === ""
                                    ? "root object"
                                    : error.instancePath;

                            const paramsString = Object.values(
                                error.params
                            ).map((v) => v.toString());

                            const message = `${path} ${error.message}: ${paramsString}`;

                            return {
                                from: 0,
                                message: message,
                                severity: "error",
                                to: 0,
                            };
                        }
                    );

                    return diagnostics;
                }
            } catch (e) {}
        }

        return [];
    };

    const makeJsonlLinter = () => {
        return linter(getJsonDiagnostics);
    };

    return (
        <CodeMirror
            value={json}
            height={height}
            extensions={[
                jsonLang(),
                makeJsonlLinter(),
                lineNumbers({ formatNumber: (x) => (x - 1).toString() }), // change code mirrors line number to index at zero
                lintGutter({}), // show linting error in gutter
            ]}
            onCreateEditor={onCreateEditor}
            onChange={onChangeInternal}
            onUpdate={onUpdateInternal}
            theme={vscodeDark} // select a theme from here: https://uiwjs.github.io/react-codemirror/#/theme/home
            readOnly={readOnly}
        />
    );
};
