import React, { useState, useEffect } from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { useParams } from "react-router-dom";

import { JsonSchemaEditor } from "../component/JsonSchemaEditor";
import { JsonEditor } from "../component/JsonEditor";

import { jsonToFormattedString } from "../utils";

import jmespath from "jmespath";
import {
    getDataTransformation,
    updateDataTransformation,
} from "../backendApis/dataTransformation";

export const DataTransformation = () => {
    let { id } = useParams();

    const [name, setName] = useState<string>("");
    const [inputSchema, setInputSchema] = useState("{}");
    const [transformation, setTransformation] = useState("{}");
    const [outputSchema, setOutputSchema] = useState("{}");

    const [testInput, setTestInput] = useState(
        jsonToFormattedString({ a: 1, b: { c: 2 } })
    );
    const [testOutput, setTestOutput] = useState("{}");

    useEffect(() => {
        if (!!id) {
            getDataTransformation(id)
                .then((transformationData) => {
                    setInputSchema(
                        jsonToFormattedString(transformationData.input_schema)
                    );
                    setTransformation(
                        jsonToFormattedString(transformationData.transformation)
                    );
                    setOutputSchema(
                        jsonToFormattedString(transformationData.output_schema)
                    );

                    setName(transformationData.name);
                })
                .catch((err) => {
                    console.error(err.message);
                });
        }
    }, [id]);

    const save = () => {
        if (!!id) {
            updateDataTransformation({
                id: parseInt(id),
                input_schema: JSON.parse(inputSchema),
                transformation: JSON.parse(transformation),
                output_schema: JSON.parse(outputSchema),
            });
        }
    };

    useEffect(() => {
        try {
            // jmespath.search required that the values were not quoted
            // and does not require that keys are quoted. So this is
            // a bit of a hack to use jmespath.search on valid json
            const transformation_ = transformation.replace(/["]/g, "");

            const result = jmespath.search(
                JSON.parse(testInput),
                transformation_
            );
            setTestOutput(jsonToFormattedString(result));
        } catch (err) {
            setTestOutput("null");
        }
    }, [testInput, transformation]);

    const onTransformationChange = (valid: boolean, data?: string) => {
        if (data !== undefined) {
            setTransformation(data);
        }
    };

    const onInputSchemaChange = (valid: boolean, data?: string) => {
        if (valid && data !== undefined) {
            setInputSchema(data);
        }
    };

    const onOutputSchemaChange = (valid: boolean, data?: string) => {
        if (valid && data !== undefined) {
            setOutputSchema(data);
        }
    };

    const onTestInputChange = (valid: boolean, data?: string) => {
        if (data !== undefined) {
            setTestInput(data);
        }
    };

    const maxEditorHeight = "512px";

    return (
        <div>
            <Box sx={{ flexGrow: 1, paddingBottom: "15px" }}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={{ xs: "column", sm: "row" }}
                    sx={{ fontSize: "12px" }}
                >
                    <Grid sx={{ order: { xs: 2, sm: 1 } }}>
                        <Typography variant="h5">{name}</Typography>
                    </Grid>
                    <Grid
                        container
                        columnSpacing={1}
                        sx={{ order: { xs: 1, sm: 2 } }}
                    >
                        <Grid>
                            <Button
                                variant="contained"
                                color={"primary"}
                                onClick={save}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <div
                style={{
                    display: "flex",
                    flexFlow: "row",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        padding: "0px",
                        marginRight: "10px",
                    }}
                >
                    <Typography variant="h5">Input Schema</Typography>
                    <Card>
                        <JsonSchemaEditor
                            height={maxEditorHeight}
                            jsonSchema={inputSchema}
                            onChange={onInputSchemaChange}
                        ></JsonSchemaEditor>
                    </Card>
                </div>
                <div
                    style={{
                        width: "100%",
                        padding: "0px",
                        marginLeft: "10px",
                    }}
                >
                    <Typography variant="h5">Output Schema</Typography>
                    <Card>
                        <JsonSchemaEditor
                            height={maxEditorHeight}
                            jsonSchema={outputSchema}
                            onChange={onOutputSchemaChange}
                        ></JsonSchemaEditor>
                    </Card>
                </div>
            </div>

            <Typography variant="h5" style={{ marginTop: "15Px" }}>
                Transformation (JMESPath)
            </Typography>
            {/* <Typography variant="h6">Transformation</Typography> */}
            {/* <p>
                JMESPath:{" "}
                <a href="https://jmespath.org/">https://jmespath.org/</a>
            </p> */}
            <Card>
                <JsonEditor
                    json={transformation}
                    onChange={onTransformationChange}
                ></JsonEditor>
            </Card>

            <Typography variant="h5" style={{ marginTop: "15Px" }}>
                Test Area
            </Typography>
            <div style={{ display: "flex", flexFlow: "row" }}>
                <div
                    style={{
                        width: "100%",
                        padding: "0px",
                        marginRight: "10px",
                    }}
                >
                    <Typography variant="h6" style={{ marginTop: "15Px" }}>
                        Test input (validated by input schema)
                    </Typography>
                    <Card>
                        <JsonEditor
                            height={maxEditorHeight}
                            json={testInput}
                            schema={inputSchema}
                            onChange={onTestInputChange}
                        ></JsonEditor>
                    </Card>
                </div>
                <div
                    style={{
                        width: "100%",
                        padding: "0px",
                        marginLeft: "10px",
                    }}
                >
                    <Typography variant="h6" style={{ marginTop: "15Px" }}>
                        Example Output (validated by output schema)
                    </Typography>
                    <Card>
                        <JsonEditor
                            height={maxEditorHeight}
                            readOnly={true}
                            json={testOutput}
                            schema={outputSchema}
                        ></JsonEditor>
                    </Card>
                </div>
            </div>
        </div>
    );
};

// {
//     "names": [
//       {"id": 0, "name": "ada"},
//       {"id": 1, "name": "bob"}
//     ],
//     "ages": [
//       {"id": 0, "age": 32},
//       {"id": 0, "age": 32}
//     ]
//   }

// {
//     "type": "object",
//     "properties": {
//       "x": {
//         "type": "string"
//       }
//     },
//     "required": ["x"]
//   }
