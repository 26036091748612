import React, { useEffect } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";

import {
    DataTransformationSummary,
    getDataTransformations,
} from "../../backendApis/dataTransformation";
import {
    createProblemInstanceNode,
    CreateProblemInstanceNode,
    ProblemInstanceNode,
} from "../../backendApis";

export const AddDataTransformationDialog = ({
    problemInstanceId,
    displayed,
    cancel,
    create,
    name,
}: {
    problemInstanceId: number;
    displayed: boolean;
    cancel: () => void;
    create: (node: ProblemInstanceNode) => void;
    name: string;
}) => {
    const [open, setOpen] = React.useState(displayed);

    const [selected, setSelected] = React.useState<
        DataTransformationSummary | undefined
    >(undefined);

    const [transformation, setTransformation] = React.useState<
        DataTransformationSummary[]
    >([]);

    useEffect(() => {
        setOpen(displayed);

        getDataTransformations().then((datasets) => {
            setTransformation(datasets);
        });
    }, [displayed]);

    const handleClose = (created: boolean) => {
        if (created === false) {
            setOpen(false);
            cancel();
            return;
        }

        if (!selected) {
            return;
        }

        const playLoad: CreateProblemInstanceNode = {
            problem_instance: problemInstanceId,
            type: "DATA_TRANSFORMATION",
            data_transformation: selected.id,
            x_position: 50,
            y_position: 50,
            webhooks: [],
        };

        createProblemInstanceNode(playLoad)
            .then((node) => {
                create(node);
                setSelected(undefined);
                setOpen(false);
            })
            .catch((error) => {});
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{`${name} Selector`}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`Select a ${name}`}</DialogContentText>
                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                    <InputLabel htmlFor="demo-dialog-native">{name}</InputLabel>
                    <Select
                        native
                        value={JSON.stringify(selected)}
                        onChange={(event: SelectChangeEvent) => {
                            const dataset = JSON.parse(event.target.value);
                            if (dataset["id"] !== -1) {
                                setSelected(dataset);
                            } else {
                                setSelected(undefined);
                            }
                        }}
                        renderValue={(x) => JSON.parse(x)["name"]}
                        input={<OutlinedInput id="demo-dialog-native" />}
                    >
                        <option
                            key={-1}
                            value={JSON.stringify({ id: -1, name: "sdfsdf" })}
                        >
                            None
                        </option>
                        {transformation.map((dataset) => (
                            <option
                                key={dataset.id}
                                value={JSON.stringify(dataset)}
                            >
                                {dataset.name}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <DialogContentText>{selected?.details}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Cancel</Button>
                <Button onClick={() => handleClose(true)}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};
