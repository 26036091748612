import { authenticatedFetch } from "./shared";

export interface DataTransformationCreate {
    name: string;
    details: string;
    input_schema: object;
    transformation: object;
    output_schema: object;
}

export interface DataTransformationSummary extends DataTransformationCreate {
    id: number;
}

export interface DataTransformation extends DataTransformationSummary {
    // id: number;
}

export interface DataTransformationUpdate {
    id: number;
    name?: string;
    details?: string;
    input_schema?: object;
    transformation?: object;
    output_schema?: object;
}

export const getDataTransformations = (): Promise<
    DataTransformationSummary[]
> => {
    return authenticatedFetch(`api/v1/data_transformations/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json.list;
        })
        .catch(() => {});
};

export const getDataTransformation = (
    id: string
): Promise<DataTransformation> => {
    return authenticatedFetch(`api/v1/data_transformations/${id}/`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .then((json) => {
            return json;
        })
        .catch(() => {});
};

export const createDataTransformation = (
    data: DataTransformationCreate
): Promise<DataTransformation> => {
    return authenticatedFetch(`api/v1/data_transformations/`, {
        method: "POST",

        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};

export const updateDataTransformation = (
    data: DataTransformationUpdate
): Promise<void> => {
    return authenticatedFetch(`api/v1/data_transformations/${data.id}/`, {
        method: "PATCH",

        body: JSON.stringify(data),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                debugger;
                window.location.href = "/login";
            }
        })
        .catch(() => {});
};
