import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { xml } from "@codemirror/lang-xml";
import { lintGutter } from "@codemirror/lint";
import { EditorView, lineNumbers } from "@codemirror/view";

import {
    makeXmlLinter,
    getXmlDiagnostics,
    getErrorDiagnostics,
} from "./XmlLinter";

import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { ViewUpdate } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { xcspSpec } from "./XcspSpec";

interface XcspEditorProps {
    xcsp?: string;
    onChange?: (valid: boolean, src?: string) => void;
    readOnly?: boolean;
}

export default function XcspEditor({
    xcsp,
    onChange,
    readOnly,
}: XcspEditorProps) {
    const onChangeInternal = (xcsp: string, viewUpdate: ViewUpdate) => {
        if (!!onChange) {
            const errors = getErrorDiagnostics(
                getXmlDiagnostics(xcspSpec, viewUpdate.view)
            );
            const validXcsp = errors.length === 0;
            onChange(validXcsp, xcsp);
        }
    };

    const onCreateEditor = (view: EditorView, state: EditorState) => {
        if (!!onChange) {
            const diagnostics = getXmlDiagnostics(xcspSpec, view);
            const errors = diagnostics.filter((x) => x.severity === "error");

            const validXcsp = errors.length === 0;
            onChange(validXcsp);
        }
    };

    return (
        <CodeMirror
            value={xcsp}
            // maxHeight="200px"
            extensions={[
                xml({ elements: xcspSpec.elementSpecs, attributes: [] }), // @codemirror/lang-xml is used to provide the auto completion
                makeXmlLinter(xcspSpec), // custom linter is used for validating xml based on schema (which is an extended version of that used by @codemirror/lang-xml)
                lineNumbers({ formatNumber: (x) => (x - 1).toString() }), // change code mirrors line number to index at zero
                lintGutter({}), // show linting error in gutter
            ]}
            onCreateEditor={onCreateEditor}
            onChange={onChangeInternal}
            theme={vscodeDark} // select a theme from here: https://uiwjs.github.io/react-codemirror/#/theme/home
            readOnly={readOnly}
        />
    );
}
